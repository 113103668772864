
const DashboardCounts = (props) =>
{
    const pass = props.moduleResults.filter(x => x.Decision === "Pass")
    const fail = props.moduleResults.filter(x => x.Decision === "Fail")

    const date_difference = props.semesterProgress.length > 0 ? props.semesterProgress[0].date_difference : 0
    const todays_diff = props.semesterProgress.length > 0 ? props.semesterProgress[0].todays_diff : 0
    let semesterProg;
    if (props.semesterProgress.length > 0)
    {
        semesterProg = (todays_diff / date_difference) * 100
    } else
    {
        semesterProg = 100
    }

    return (
        <div className="card card-flush h-xl-100">
            <div className="rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px" style={{ backgroundColor: '#080655', backgroundImage: 'url("assets/media/svg/shapes/wave-bg-dark.svg")' }}>

                <div className='container container-fluid mt-5 mb-10'>
                    <div className="card-title d-flex flex-column">
                        <div className='row mt-4'>
                            <div className='col-md-3'>
                                <span className="fs-3hx fw-bolder text-white me-2 lh-1 ls-n2">{props.registredModules.length}</span><br />
                                <span className="text-white opacity-100 pt-1 fw-bold fs-6">Registered Modules</span>
                            </div>
                            <div className='col-md-3'>
                                <span className="fs-3hx fw-bolder text-white me-2 lh-1 ls-n2">{pass.length}</span><br />
                                <span className="text-white opacity-100 pt-1 fw-bold fs-6">Passed Modules</span>
                            </div>
                            <div className='col-md-3'>
                                <span className="fs-3hx fw-bolder text-white me-2 lh-1 ls-n2">
                                    {fail.length}</span><br />
                                <span className="text-white opacity-100 pt-1 fw-bold fs-6">
                                    Failed Modules
                                </span>
                            </div>
                            <div className='col-md-3'>
                                <span className="fs-3hx fw-bolder text-white me-2 lh-1 ls-n2">
                                    {
                                        props.registredModules.length > 0 ?
                                            props.registredModules.filter(x => x.SemesterCode === props.currentsemester).length
                                            : 0
                                    }
                                </span><br />

                                <span className="text-white opacity-100 pt-1 fw-bold fs-6">
                                    Currently Taking
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card-body d-flex align-items-end pt-10 mt-5">
                    <div className="d-flex align-items-center flex-column mt-3 w-100">
                        <div className="d-flex justify-content-between fw-bolder fs-6 text-white opacity-100 w-100 mt-auto mb-2">
                            <span>{props.currentsemester} Semester Progress ({date_difference-todays_diff} days remaining)</span>
                            <span>{semesterProg.toFixed(0) + "%"}</span>
                        </div>
                        <div className="h-8px mx-3 w-100 bg-light-danger rounded">
                            <div className="bg-success rounded h-8px" role="progressbar" style={{ width: semesterProg.toFixed(0) +"%" }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default DashboardCounts;