import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { setHostelDetails } from "../../../actions/setactiondetails";
import { useNavigate } from "react-router";
import { set } from "immutable";

function Hostel(props) {
    const token = props.loginData[0].token;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [hostelList, setHostelList] = useState([])
    const [hostelDetails, setHostelDetails] = useState([])
    const [hostelbeds, setHostelBeds] = useState([])
    const [checkFinance, setCheckFinance] = useState([])
    const gender = props.loginData[0].StudentGender;
    const studentID = props.loginData[0].StudentID

    const getData = async () => {
        await axios.get(`${serverLink}students/hostel/get/hostel/${gender}`, token).then((result) => {
            if (result.data.length > 0) {
                setHostelList(result.data)
            }
            setIsLoading(false)

        })
        await axios.get(`${serverLink}students/hostel/get/hostel/bed/${gender}`, token).then((result) => {
            if (result.data.length > 0) {
                setHostelBeds(result.data)
            }
        })
        await axios.get(`${serverLink}students/hostel/get/hostel/details/${gender}`, token).then((result) => {
            if (result.data.length > 0) {
                setHostelDetails(result.data)
            }
        })
        await axios.post(`${serverLink}students/hostel/check/hostel/clearance`, { studentID: studentID }, token).then((result) => {
            setCheckFinance(result.data)
        })
    }

    const onSubmit = async (HostelID) => {
        toast.info("please wait...")
        props.setOnHostelDetails(HostelID)
        setTimeout(() => {
            navigate(`/student/hostel/view-room`)
        }, 500)

    };

    useEffect(() => {
        getData();
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid" id="kt_content">
            <div className="row g-5 g-lg-8 mb-5 mb-xl-0">

                {
                    checkFinance.length === 0 &&
                    <div className="text-center alert alert-dismissible bg-danger text-white" role="alert">
                        Sorry, you do not have permission to reserve a room. Please clear with finance
                    </div>
                }

                {
                    hostelList.length > 0 ?
                        hostelList.map((z, j) => {
                            const fl_ = hostelDetails.filter(x => x.HostelID === z.EntryID);
                            const floors = [...new Set(fl_.map(x => x.FloorName))];
                            const rooms = [...new Set(fl_.map(y => y.RoomNo))]

                            const beds_ = hostelbeds.filter(x => x.HostelID === z.EntryID);
                            const reservedBeds_ = beds_.filter(x => x.Status === 'Reserved')
                            const availableBeds_ = beds_.filter(x => x.Status === 'Available')
                            const underMaintenanceBeds_ = beds_.filter(x => x.Status === 'Under Maintenance')
                            const occupiedBeds_ = beds_.filter(x => x.Status === 'Occupied')

                            return (

                                <div className="col-xl-4 mb-xl-8" key={j}>
                                    <div className="card h-xl-100">
                                        <div className="card-header border-0 pt-5">
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="font-size-lg card-label fw-bold text-dark" ><h2 className="text-center" >{z.HostelName}</h2></span>
                                            </h3>
                                        </div>

                                        <div className="card-body pt-5">
                                            <div className="d-flex align-items-sm-center mb-7">
                                                <div className="symbol symbol-50px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-home text-primary fa-2x"></span>
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold"><strong>Number of Floors</strong></a>
                                                    </div>
                                                    <div className="symbol symbol-85px ">
                                                        <span className="symbol-label bg-light-secondary fw-bolder fa-2x">
                                                            {floors.length}</span>
                                                    </div>
                                                    {/* <span className="badge badge-light fw-bold my-2">
                                                       
                                                    </span> */}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-sm-center mb-7">
                                                <div className="symbol symbol-50px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-user text-primary fa-2x"></span>
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold"><strong>{z.ManagerName}</strong></a>
                                                        <span className="text-muted fw-semibold d-block fs-7"><strong>{z.ManagerEmail}</strong></span>
                                                        <span className="text-muted fw-semibold d-block fs-7"><strong>{z.ManagerPhone}</strong></span>
                                                    </div>
                                                    <span className="badge badge-light fw-bold my-2">Manager</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-sm-center mb-7">
                                                <div className="symbol symbol-50px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-home text-primary fa-2x"></span>
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold"><strong>Number of rooms</strong></a>
                                                    </div>
                                                    <div className="symbol symbol-85px ">
                                                        <span className="symbol-label bg-light-secondary fw-bolder fa-2x">
                                                            {rooms.length}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-sm-center mb-7">
                                                <div className="symbol symbol-50px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-bed text-primary fa-2x"></span>
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bold"><strong>Number of beds</strong></a>
                                                    </div>
                                                    <div className="symbol symbol-85px ">
                                                        <span className="symbol-label bg-light-secondary fw-bolder fa-2x">
                                                            {beds_.length}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-sm-center mb-7">
                                                <div className="symbol symbol-85px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-key text-primary fa-2x"></span>
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-success fs-6 fw-bold">Reserved beds</a>
                                                    </div>
                                                    <div className="symbol symbol-85px ">
                                                        <span className="symbol-label bg-light-secondary fw-bolder fa-2x">
                                                            {reservedBeds_.length}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-sm-center">
                                                <div className="symbol symbol-50px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-bed text-success fa-2x"></span>
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-success fs-6 fw-bold">Available beds</a>

                                                    </div>
                                                    <div className="symbol symbol-85px ">
                                                        <span className="symbol-label bg-light-secondary fw-bolder fa-2x">
                                                            {availableBeds_.length}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-sm-center mt-7">
                                                <div className="symbol symbol-50px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-check text-info fa-2x"></span>
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-info fs-6 fw-bold">Occupied beds</a>
                                                    </div>
                                                    <div className="symbol symbol-85px ">
                                                        <span className="symbol-label bg-light-secondary fw-bolder fa-2x">
                                                            {occupiedBeds_.length}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-sm-center mt-7">
                                                <div className="symbol symbol-50px me-5">
                                                    <span className="symbol-label">
                                                        <span className="fa fa-paper-plane text-danger fa-2x"></span>
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <a href="#" className="text-gray-800 text-hover-danger fs-6 fw-bold">Under Maintenance beds</a>
                                                    </div>
                                                    <div className="symbol symbol-85px ">
                                                        <span className="symbol-label bg-light-secondary fw-bolder fa-2x">
                                                            {underMaintenanceBeds_.length}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-5 d-flex flex-center" id="kt_sticky_toolbar_chat_toggler_2"
                                                data-toggle="tooltip" title="" data-placement="right"
                                                data-original-title="Click to view the rooms">
                                                <button disabled={checkFinance.length === 0 ? true : false} style={{ cursor: 'pointer' }} onClick={() => {
                                                    onSubmit(z)
                                                }} className={`btn btn-success col-md-12 py-8 rounded-xl mt-3`}>View Room</button>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            )
                        })
                        : <div className="text-center alert alert-dismissible bg-danger text-white" role="alert">
                        Sorry, no available hostel
                    </div>
                }


            </div>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHostelDetails: (p) => {
            dispatch(setHostelDetails(p));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Hostel);
