import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import Loader from '../common/loader/loader';
import DashboardTimeTable from './sections/section-timetable';
import DashboardStudentInfo from './sections/section-info';
import DashboardCounts from './sections/sections-counts';
import PerformanceCharts from './sections/section-student-performance-chart';
import { connect } from "react-redux";

const DashBoard = (props) => {
  const token = props.loginData[0].token;
  const [loading, setisLoading] = useState(true)
  const [timetable, setTimetable] = useState([]);
  const [registredModules, setRegisteredModules] = useState([])
  const [moduleResults, setModueResults] = useState([])
  const [studentInfo, setStudentInfo] = useState(props.loginData)
  const [cgpa, setCgpa] = useState([])
  const [gpa, setGpa] = useState([])
  const [semesterProgress, setSemesterProgress] = useState([])
  const [gradePoint, setGradePoint] = useState([])


  const currentsemester = props.currentSemester
  const studentID = props.loginData[0].StudentID

  const [studentInformation, setStudentInfromation] = useState({
    studentID: props.loginData[0].StudentID,
    studentName: props.loginData[0].StudentName,
    studentCourse: props.loginData[0].StudentCourse,
    studentLevel: props.loginData[0].StudentLevel,
    studentSemester: props.loginData[0].StudentSemester,
    studentStatus: props.loginData[0].StudentStatus,
    sem: props.currentsemester
  })


  const getData = async () => {
    axios.post(`${serverLink}students/dashboard/get_data`, { studentID: studentID }, token).then((result) => {
      if (result.data.length > 0) {
        setTimetable(result.data[0].timetable);
        setRegisteredModules(result.data[0].registered);
        setSemesterProgress(result.data[0].progress);
        setModueResults(result.data[0].result)
      }
      setisLoading(false)
    })

    // axios.post(`${serverLink}students/dashboard/fetch/gpa`, { studentID: studentID }, token).then((result) => {
    //   if (result.data.length > 0) {
    //     setGpa(result.data[0].gpa)
    //     setCgpa(result.data[0].cgpa)
    //   }
    // })


    // axios.post(`${serverLink}students/dashboard/check/student/has/result`, { studentID: studentID }, token).then((result) => {
    //   setCheckResults(result.data)
    // })

    axios.post(`${serverLink}students/change-of-course/get/student/result`, { studentID: studentID }, token).then((result) => {
      if (result.data.length > 0) {
        const total_credit = result.data.map(e => parseInt(e.CreditLoad)).reduce((a, b) => a + b, 0);
        const total_grade = result.data.map(e => parseInt(e.GradePoints)).reduce((a, b) => a + b, 0)
        const gpa_ = total_credit !== 0 ? total_grade / total_credit : 0
        const gpa = parseFloat(gpa_.toFixed(2));
        setCgpa(gpa)
      }
    })

    axios.post(`${serverLink}students/dashboard/student/result/graph`, { studentID: studentID }, token).then((result) => {
      if (result.data.length > 0) {
        setGradePoint(result.data)
      }
    })

   
  }

  useEffect(() => {
    getData();
  }, [])

  return loading === true ? (
    <Loader />
  ) : (
    <>
      <div>
        <div id="kt_content_container" className="">
          <div className="" id="kt_content">
            <div className="">
              <div className="">

                <div className="row g-5">
                  {/* <a target={"_blank"} href={`login-from-staff/${encryptData("IT/COM/22A/0004")}`}>test</a> */}
                  <div className="col-xl-6">
                    {
                      studentInfo.length > 0 &&
                      <DashboardStudentInfo
                        studentInfo={studentInfo}
                        studentInformation={studentInformation}
                      />
                    }
                  </div>
                  <div className='col-xl-6'>

                    <DashboardCounts
                      moduleResults={moduleResults}
                      registredModules={registredModules}
                      semesterProgress={semesterProgress}
                      currentsemester={currentsemester}
                    />
                  </div>

                  {<div className="col-xl-12">
                    <PerformanceCharts
                      cgpa={cgpa}
                      gradePoint={gradePoint}
                    />
                  </div>}

                  <div className="col-xl-12">
                    <DashboardTimeTable
                      timetable={timetable}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
    currentSemester: state.currentSemester
  };
};

export default connect(mapStateToProps, null)(DashBoard);