import React from "react";
import "react-select2-wrapper/css/select2.css";
import Select from 'react-select'
import JoditEditor from "jodit-react";

export default function RegistrationComplainForm(props) {
    return (
        <>
         <div className="form-group mb-4">
                <label htmlFor="ComplainType">Complain Type</label>
                <select
                  name="ComplainType"
                  id="ComplainType"
                  className="form-select"
                  onChange={props.onEdit}
                  value={props.data.ComplainType}
                >
                <option value="">Select Option</option>
                <option value="Add">Add Module(s)</option>
                <option value="Drop">Drop Module(s)</option>
                <option value="Other">Other</option>
                </select>
            </div>
             <div className="form-group mb-4">
                <label htmlFor="ModuleCode">Module Code</label>
                <Select
                    name="ModuleCode"
                    isMulti
                    value={props.data.ModuleCode}
                    onChange={props.ModuleCodeEdit} 
                    options={props.moduleList}
                />
            </div>
           
            <div className="form-group mb-4 col-md-12">
                    <label htmlFor="Description">Complain Description</label>
                    <JoditEditor
                id="Description"
                name="Description"
                tabIndex={1}
                onChange={props.onReasonChange}
            />
                </div>
            <div className="form-group pt-2">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                    </span>
                </button>
            </div>
             
        </>
    )
}