import React from "react";
import { Link } from "react-router-dom";
import {projectName, projectStudentPortal} from "../../../resources/constants";

export default function Footer() {
    return (
        <div className="footer py-4 d-flex flex-lg-column hideFooter" id="kt_footer">
            <div
                className="container-custom container-xxl d-flex hideFooter flex-column flex-md-row align-items-center justify-content-between">
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-bold me-1">2022©</span>
                    <a href={projectStudentPortal} target="_blank"
                       className="text-gray-800 text-hover-primary">{projectName}</a>
                </div>
                <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                    <li className="menu-item">
                        <Link className="menu-link px-2" to={"student/hostel"}>Hostel Portal</Link>
                    </li>
                    <li className="menu-item">
                    <Link className="menu-link px-2" to={"student/service-desk"}>Service Desk Portal</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}