import React from "react";
import SimpleFileUpload from 'react-simple-file-upload';
import { projectshortCode, simpleFileUploadAPIKey } from '../../../resources/constants'
export default function InternshipForm(props) {
    return (
        <>
            {
                props.internshipData === 1 ?

                    <>
                        <div className="d-flex flex-column mb-7 fv-row">
                            <label className="fs-6 fw-bold mb-2">
                                <span className="">Supervisor Name</span>
                            </label>
                            <input
                                type="text"
                                placeholder="SupervisorName"
                                id={"SupervisorName"}
                                name="SupervisorName"
                                onChange={props.onEdit2}
                                className={"form-control"}
                                value={props.uploadInternship.SupervisorName}
                            />
                        </div>
                        <div className="d-flex flex-column mb-7 fv-row">
                            <label className="fs-6 fw-bold mb-2">
                                <span className="">Supervisor PhoneNumber</span>
                            </label>
                            <input
                                type="number"
                                placeholder="SupervisorPhoneNumber"
                                id={"SupervisorPhoneNumber"}
                                name="SupervisorPhoneNumber"
                                onChange={props.onEdit2}
                                className={"form-control"}
                                value={props.uploadInternship.SupervisorPhoneNumber}
                            />
                        </div>
                        <div className="d-flex flex-column mb-7 fv-row">
                            <label className="fs-6 fw-bold mb-2">
                                <span className="">Upload Acceptance Document (PDF File)</span>
                            </label>
                            {/* <SimpleFileUpload
                                apiKey={simpleFileUploadAPIKey}
                                tag={`${projectshortCode}-student-internship`}
                                onSuccess={props.handleAcceptanceDocument}
                                preview="false"
                                width="100%"
                                height="100"
                                accept="application/pdf"

                            /> */}
                            <input type="file" id="AcceptanceDocument" className="form-control" onChange={props.onFileChange} />
                        </div>

                        <div className="form-group pt-2">
                            <button onClick={props.onSubmit2} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>

                        </div>
                    </>
                    :
                    <>
                        <div className="form-group mb-4">
                            <label htmlFor="CompanyName">Company Name</label>
                            <input
                                type={"text"}
                                id="CompanyName"
                                name="CompanyName"
                                className="form-control"
                                placeholder="Enter the Company Name"
                                onChange={props.onEdit}
                                value={props.data.CompanyName}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="CompanyAddress">Company Address</label>
                            <input
                                type={"text"}
                                id="CompanyAddress"
                                name="CompanyAddress"
                                className="form-control"
                                placeholder="Enter the Company Address"
                                onChange={props.onEdit}
                                value={props.data.CompanyAddress}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="CompanyState">Company State</label>
                            <input
                                type={"text"}
                                id="CompanyState"
                                name="CompanyState"
                                className="form-control"
                                placeholder="Enter the Company State"
                                onChange={props.onEdit}
                                value={props.data.CompanyState}
                            />
                        </div>

                        <div className="form-group pt-2">
                            <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                <span className="indicator-label">Submit</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>

                        </div>
                    </>
            }
        </>
    )
}