import { serverLink } from "./url";
import axios from "axios";

const CryptoJS = require("crypto-js");

// export const projectName = "Baba Ahmed University | Student Portal";
// export const projectCode = "BABA_AHMED_UNIVERSITY_STUDENT_PORTAL";
// export const projectLogo = "https://babaahmeduniversity.edu.ng/logo.png";
// export const projectURL = "https://babaahmeduniversity.edu.ng";
// export const projectPhone = "+234 803 347 8966";
// export const projectEmail = "info@babaahmeduniversity.edu.ng";
// export const projectHREmail = "hr@babaahmeduniversity.edu.ng";
// export const projectAddress = "Sharada Kano, Kano State Nigeria.";
// export const projectStudentPortal = "https://student.babaahmeduniversity.edu.ng/"
// export const projectNameOnTranscript = "BABA AHMED UNIVERSITY"
// export const projectshortCode = "BAU"
// export const projectSiwesCoordinator = "SIWES Coordinator"


// export const projectName = "Olivia University | Student Portal";
// export const projectCode = "OLIVIA_UNIVERSITY_STAFF_PORTAL";
// export const projectLogo = "https://oliviauniversity.com/logo.png";
// export const projectURL = "https://oliviauniversity.com";
// export const projectPhone = "+25767800004";
// export const projectEmail = "info@oliviauniversity.com";
// export const projectHREmail = "hr@oliviauniversity.com";
// export const projectAddress = "livia University Bujumbura, № 4&5, Mayugi, Avenue, Mukaza, Bujumbura-Burundi";
// export const projectStudentPortal = "https://student.oliviauniversity.com/"
// export const projectNameOnTranscript = "OLIVIA UNIVERSITY BUJUMBURA BURUNDI"
// export const projectshortCode = "OUB"
// export const projectSiwesCoordinator = "SIWES Coordinator"


export const projectName = "Al-Ansar University | Staff Portal";
export const projectCode = "AL_ANSAR_UNIVERSITY_STUDENT_PORTAL";
export const projectLogo = "https://aum.edu.ng/logo.png";
export const projectURL = "https://aum.edu.ng";
export const projectPhone = "+234 803 629 5382";
export const projectEmail = "info@aum.edu.ng";
export const projectHREmail = "hr@aum.edu.ng";
export const projectAddress = "Maiduguri, Borno State, Nigeria.";
export const projectStudentPortal = "https://student.aum.edu.ng/"
export const projectNameOnTranscript = "AL-ANSAR UNIVERSITY, MAIDUGURI"
export const projectNameOnTranscript2 = "(COLLEGE OF ARTS, MANAGEMENT AND SOCIAL SCIENCES)"
export const projectshortCode = "AUM"
export const projectSiwesCoordinator = "SIWES Coordinator"


// export const projectName = "Lux Mundi University | Student Portal";
// export const projectCode = "LUX_MUNDI_UNIVERSITY_STUDENT_PORTAL";
// export const projectLogo = "https://luxmundi.edu.ng/logo.png";
// export const projectURL = "https://luxmundiuniversity.edu.ng";
// export const projectPhone = "+234 803 347 8966";
// export const projectEmail = "info@luxmundiuniversity.edu.ng";
// export const projectHREmail = "hr@luxmundiuniversity.edu.ng";
// export const projectAddress = "SIsiama Afaraukwu-Ibeku, Umuahia, Abia State, Nigeria.";
// export const projectStudentPortal = "https://student.luxmundi.edu.ng/";
// export const projectNameOnTranscript = "LUX MUNDI UNIVERSITY";
// export const projectshortCode = "LMU";
// export const projectSiwesCoordinator = "SIWES Coordinator";

//
// export const projectName = "Cosmopolitan University | Student Portal";
// export const projectCode = "COSMOPOLITAN_UNIVERSITY_STUDENT_PORTAL";
// export const projectLogo = "https://cosmopolitan.edu.ng/logo.png";
// export const projectURL = "https://cosmopolitan.edu.ng";
// export const projectPhone = "+234 803 347 8966";
// export const projectEmail = "info@cosmopolitan.edu.ng";
// export const projectHREmail = "hr@cosmopolitan.edu.ng";
// export const projectAddress = "Abuja Nigeria.";
// export const projectStudentPortal = "https://student.cosmopolitan.edu.ng/"
// export const projectNameOnTranscript = "COSMOPOLITAN UNIVERSITY"
// export const projectshortCode = "CU"
// export const projectSiwesCoordinator = "SIWES Coordinator"
//
export const simpleFileUploadAPIKey = "f4aa216109536cd638b21812f5ba7432";


export const formatDateAndTime = (date, option) =>
{
  if (date !== null)
  {
    const user_date = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day =
      user_date.getDate() < 10
        ? "0" + user_date.getDate()
        : user_date.getDate();
    const hour =
      user_date.getHours() < 10
        ? "0" + user_date.getHours()
        : user_date.getHours();
    const min =
      user_date.getMinutes() < 10
        ? "0" + user_date.getMinutes()
        : user_date.getMinutes();
    const sec =
      user_date.getSeconds() < 10
        ? "0" + user_date.getSeconds()
        : user_date.getSeconds();

    let date_string = "";
    if (option === "date_and_time")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
    else if (option === "date")
      date_string = `${day}-${monthNames[user_date.getMonth()]
        }-${user_date.getFullYear()}`;
    else if (option === "day") date_string = day;
    else if (option === "full_month")
      date_string = monthNames[user_date.getMonth()];
    else if (option === "short_month")
      date_string = monthNamesShort[user_date.getMonth()];
    else if (option === "year_only") date_string = user_date.getFullYear();

    return date_string;
  } else
  {
    return "--";
  }
};

export const formatDate = (date) =>
{
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const currencyConverter = (amount) =>
{
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });
  return formatter.format(amount);
};

export const sendEmail = (email, subject, title, name, body, signature) =>
{
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
  };
  axios.post(`${serverLink}send_email/send`, sendEmail).then((r) =>
  {
    console.log("email sent");
  });

  return "sent";
};

export const admissionEmail = (
  email,
  app_id,
  subject,
  title,
  name,
  body,
  signature
) =>
{
  const sendEmail = {
    logo: projectLogo,
    from: projectEmail,
    to: email,
    app_id,
    subject: subject,
    title: title,
    name: name,
    body: body,
    signature: signature,
  };
  axios.post(`${serverLink}send_email/send`, sendEmail).then((r) =>
  {
    console.log("email sent");
  });

  return "sent";
};

export function encryptData(string, val = false)
{
  let secret_key =
    val === false
      ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT"
      : projectCode;
  let secret_iv =
    val === false
      ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT_IV"
      : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

  let result = decrypted.toString();
  return btoa(result);
}

export function decryptData(string, val = false)
{
  let secret_key =
    val === false
      ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT"
      : projectCode;
  let secret_iv =
    val === false
      ? "BABA_AHMED_UNIVERSITY_STAFF_PORTAL" + "_ENCRYPT_IV"
      : projectCode;
  // hash
  let kee = CryptoJS.SHA256(secret_key);
  let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

  kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
  ivv = CryptoJS.enc.Utf8.parse(ivv);

  var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

  var result = decrypted.toString(CryptoJS.enc.Utf8);
  return result;
}

export const TimeTablePeriods = [
  { value: "6", label: "6:00 am" },
  { value: "7", label: "7:00 am" },
  { value: "8", label: "8:00 am" },
  { value: "9", label: "9:00 am" },
  { value: "10", label: "10:00 am" },
  { value: "11", label: "11:00 am" },
  { value: "12", label: "12:00 pm" },
  { value: "13", label: "1:00 pm" },
  { value: "14", label: "2:00 pm" },
  { value: "15", label: "3:00 pm" },
  { value: "16", label: "4:00 pm" },
  { value: "17", label: "5:00 pm" },
  { value: "18", label: "6:00 pm" },
  { value: "19", label: "7:00 pm" },
];

export const EmailTemplates = (type, student) =>
{
  if (type === "1")
  {
    const message = {
      subject: "REGISTRATION COMPLAIN",
      title: "REGISTRATION COMPLAIN",
      body: `<div style="line-height: 1.6">Kindly note that your registration complain has been submitted successfully.
      <br><br>
      You will received another email when a decision is made. <br><br>
      Good luck and regards.
      <br><br>
      <a href="${projectStudentPortal}">Click here to log in to your Student Portal</a><br><br>
      <small>Please ignore this email if it is not relevant to you</small>.<br><br>
      Thank you</div>`,
    };
    return message;
  } else if (type === "2")
  {
    const message = {
      subject: "PROGRESSION COMPLAIN",
      title: "PROGRESSION COMPLAIN",
      body: `<div style="line-height: 1.6">Kindly note that your progression complain has been submitted successfully.
      <br><br>
      Current Level and Semester: <strong>${student.CurrentLevel} ${student.CurrentSemester}</strong> <br><br>
      Applied Level and Semester: <strong>${student.NewLevel} ${student.NewSemester}</strong> <br><br>
      You will received another email when a decision is made. <br><br>
      Good luck and regards.
      <br><br>
      <a href="${projectStudentPortal}">Click here to log in to your Student Portal</a><br><br>
      <small>Please ignore this email if it is not relevant to you</small>.<br><br>
      Thank you.</div>`
    }
    return message;
  }
  else if (type === '3')
  {
    const message = {
      subject: 'SEMESTER DEFERMENT',
      title: 'SEMESTER DEFERMENT',
      body: `<div style="line-height: 1.6">Your deferment form has been submitted successfully.
          <br><br>
          You have applied for <strong>${student.Semester}</strong> semester(s) deferment.
          <br><br>
          You will receive another email when decision is made.
          <br><br>
          Good luck and regards.
          <br><br>
          <a href="${projectStudentPortal}">Click here to log in to your Student Portal</a><br><br>
          <small>Please ignore this email if it is not relevant to you</small>.<br><br>
          Thank you.</div>`
    }
    return message;
  }
  else if (type === '4')
  {
    const message = {
      subject: 'CHANGE OF COURSE',
      title: 'CHANGE OF COURSE',
      body: `Your change of course form was submitted successfully.
      <br><br>
      Your current course is: <strong>${student.data.studentCourseName}</strong>
      <br><br>
      Your applied course is: <strong>${student.RequestedCourse}</strong>
      <br><br>
      You will received another email when a decision is made. <br><br>
      Kindly inform your guardian to approve your change of course. <br><br>
      <br><br>
      Good luck and regards.
      <br><br>
      <a href="${projectStudentPortal}">Click here to log in to your Student Portal</a><br><br>
      <small>Please ignore this email if it is not relevant to you</small>.<br><br>
      Thank you..</div>`
    }
    return message;
  }
  else if (type === '5')
  {
    const message = {
      subject: 'CHANGE OF COURSE | GUARDIAN APPROVAL',
      title: 'CHANGE OF COURSE | GUARDIAN APPROVAL',
      body: `Your ward ${student.data.studentName} has initiated a change of course, and has made you his/her sponsor.
      <br><br>
      Current course is: <strong>${student.data.studentCourseName}</strong>
      <br><br>
      Change to course is: <strong>${student.RequestedCourse}</strong>
      <br><br>
      You are expected to approve or reject this request by clicking the appropriate button below.
     <br><br>
      <a style="border: none;
color: white;
padding: 15px 32px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 4px 2px;
cursor: pointer;background-color: #4CAF50;" href="${projectStudentPortal}student/change-of-course/approve/${encryptData(student.EntryID + "," + "ID")}">Approve</a>
      <a style="border: none;
color: white;
padding: 15px 32px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 4px 2px;
cursor: pointer;background-color: #ff0000;" href="${projectStudentPortal}student/change-of-course/reject/${encryptData(student.EntryID + "," + "ID")}">Reject</a>
<br><br>

      <small>Please ignore this email if it is not relevant to you</small>.<br><br>
      Thank you.</div>`
    }
    return message;
  } else if (type === '6')
  {
    const message = {
      subject: "Student Portal",
      title: "password recovery",
      body: `<div style="line-height: 1.6">
      You have requested to reset your password, kindly follow this <a href='${projectStudentPortal}reset-password/${student}'>link</a> to reset your password.
      <br/>
      Or copy and paste this link in your browser ${projectStudentPortal}reset-password/${student}
      
       <br/><br/>For further enquiries, please contact <b/>${projectEmail}<br/>${projectPhone}
      </div>`,
    };
    return message;
  } else if (type === '7')
  {
    const message = {
      subject: "Hostel Portal",
      title: "Hostel bed space",
      body: `<div style="line-height: 1.6">Your bed space reservation for this <strong>${student.studentSemester}</strong> semester has been submitted successfully.
      <br><br>
      Your reseevation details is: Floor Name: <strong>${student.FloorName} Floor</strong>.
      <br><br>
      Room Number: <strong>${student.RoomNo}</strong>.
      <br><br>
      Bed Number: <strong>${student.BedNo}</strong>.
      <br><br>
      You will receive another email when decision is made.
      <br><br>
      Good luck and regards.
      <br><br>
      <small>Please ignore this email if it is not relevant to you</small>.<br><br>
      Thank you.</div>`
    };
    return message;
  }
};

export const toTitleCase = (phrase) =>
{
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
