import React from "react";
import JoditEditor from "jodit-react";

export default function ProgressionForm(props) {
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6">
                    Student ID:
                    </div>
                    <div className="col-md-6">
                    <strong>{props.data.studentID}</strong>
                    </div>
                    <div className="col-md-6">
                    Student Names:
                    </div>
                    <div className="col-md-6">
                    <strong>{props.data.StudentName}</strong>
                    </div>
                    <div className="col-md-6">
                    Carry Over Credit Units:
                    </div>
                    <div className="col-md-6">
                    <strong>{props.total}</strong>
                    </div>
                    <div className="col-md-6">
                    Carry Over Modules:
                    </div>
                    <div className="col-md-6">
                    <strong>{props.failModuleList.map(e=>e.ModuleCode).join(",")}</strong>
                    </div>
                </div>
            </div>
            <div className="form-group mb-4 mt-5">
                <label htmlFor="NewLevel">New Level</label>
                <select
                    name="NewLevel"
                    id="NewLevel"
                    className="form-select"
                    onChange={props.onEdit}
                    value={props.data.NewLevel}
                >
                    <option value="">Select New Level...</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                    <option value="Internship">Internship</option>
                    <option value="400">400</option>
                    <option value="500">500</option>
                    {props.data.CurrentLevel > 500 ?
                    <>
                    <option value="600">600</option>
                    <option value="700">700</option>
                    <option value="800">800</option>
                    <option value="900">900</option>
                    </>
                    :""
                     }
                </select>
            </div>
            <div className="form-group mb-4">
                <label htmlFor="NewSemester">New Semester</label>
                <select
                    name="NewSemester"
                    id="NewSemester"
                    className="form-select"
                    onChange={props.onEdit}
                    value={props.data.NewSemester}
                >   <option value="">Select New Semester</option>
                    <option value="First">First Semester</option>
                    <option value="Second">Second Semester</option>
                    <option value="Internship">Internship</option>
                    {props.data.CurrentLevel > 500 ?
                    <>
                    <option value="Third">Third Semester</option>
                    <option value="Fourth">Fourth Semester</option>
                    <option value="Sixth">Sixth Semester</option>
                    <option value="Seventh">Seventh Semester</option>
                    </>
                    :""
                     }
                </select>
            </div>

            <div className="form-group mb-4 col-md-12">
                <label htmlFor="Reason">Complain Reason</label>
                <JoditEditor
                id="Reason"
                name="Reason"
                tabIndex={1}
                onChange={props.onReasonChange}
            />
            </div>
            <div className="form-group pt-2">
                {
                    props.total <= 24 ? <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                </button> : <p className="alert alert-danger">Sorry, you can't submit progression complain. Your carry over credit units is greater than 24</p>
                }
            </div>

        </>
    )
}