import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import Modal from "../../common/modal/modal";
import Table from "../../common/table/table";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import TranscriptForm from "./transcript-form";
import { projectshortCode } from "../../../resources/constants";

function Transcript(props) {
    const token = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');

    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/NO",
                field: "sn",
            },
            {
                label: "Application Type",
                field: "ApplicationType",
            },
            {
                label: "Institution Name",
                field: "InstitutionName",
            },
            {
                label: "Institution Email",
                field: "InstitutionEmail",
            },
            {
                label: "Institution PhoneNo",
                field: "InstitutionPhoneNo",
            },
            {
                label: "Institution Address",
                field: "InstitutionAddress",
            },
            {
                label: "Receipt File",
                field: "ReceiptFile",
            },
            {
                label: "Status",
                field: "Status",
            },
        ],
        rows: [],
    });

    const [createTranscript, setCreateTranscript] = useState({
        studentID: props.loginData[0].StudentID,
        ApplicationType: "",
        InstitutionName: "",
        InstitutionEmail: "",
        InstitutionPhoneNo: "",
        InstitutionAddress: "",
        ReceiptFile: ""
    });

    const studentID = props.loginData[0].StudentID

    const getTranscript = async () => {
        await axios
            .post(`${serverLink}students/progression/fetch/transcript`, { studentID: studentID }, token)
            .then((result) => {

                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((transcript, index) => {
                        rows.push({
                            sn: index + 1,
                            ApplicationType: transcript.ApplicationType ?? "N/A",
                            InstitutionName: transcript.InstitutionName ?? "N/A",
                            InstitutionEmail: transcript.InstitutionEmail ?? "N/A",
                            InstitutionPhoneNo: transcript.InstitutionPhoneNo ?? "N/A",
                            InstitutionAddress: transcript.InstitutionAddress ?? "N/A",
                            ReceiptFile: transcript.ReceiptFile === "" || transcript.ReceiptFile === null ? "---" : 
                            <a href={`${serverLink}public/uploads/${projectshortCode}/student_uploads/transcript_uploads/${transcript.ReceiptFile}`} rel="noreferrer" target={"_blank"} className="btn btn-primary btn-sm">View</a> 
                            ,
                            Status: transcript.Status === 0 ? <p className="badge badge-primary">Submitted</p> : <p className="badge badge-success">Approved</p> ?? "N/A",
                            action: (
                                <button
                                    className="btn btn-sm btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() =>
                                        setCreateTranscript({
                                            ApplicationType: transcript.ApplicationType,
                                            InstitutionName: transcript.InstitutionName,
                                            InstitutionEmail: transcript.InstitutionEmail,
                                            InstitutionPhoneNo: transcript.InstitutionPhoneNo,
                                            InstitutionAddress: transcript.InstitutionAddress,
                                            SemesterCode: transcript.SemesterCode,
                                        })
                                    }
                                >
                                    <i className="fa fa-pen" />
                                </button>
                            ),
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = (e) => {
        setCreateTranscript({
            ...createTranscript,
            [e.target.id]: e.target.value,
        });
    };

    const onFileChange = (e) => {
        setCreateTranscript({
            ...createTranscript,
            [e.target.id]: e.target.files[0]
        })
    }

    const handleReceiptFile = (url) => {
        setCreateTranscript({
            ...createTranscript,
            ReceiptFile: url,
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (createTranscript.ApplicationType.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter Application Type", "error");
            return false;
        }
        if (createTranscript.InstitutionName === "") {
            showAlert("EMPTY FIELD", "Please enter Institution Name", "error");
            return false;
        }
        if (createTranscript.InstitutionEmail === "") {
            showAlert("EMPTY FIELD", "Please enter Institution Email", "error");
            return false;
        }
        if (createTranscript.InstitutionPhoneNo === "") {
            showAlert("EMPTY FIELD", "Please enter Institution PhoneNo", "error");
            return false;
        }
        if (createTranscript.InstitutionAddress === "") {
            showAlert("EMPTY FIELD", "Please enter Institution PhoneNo", "error");
            return false;
        }
        if (createTranscript.ReceiptFile === "") {
            showAlert("EMPTY FIELD", "Please Upload evidence of Payment", "error");
            return false;
        }

        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/progression/apply/transcript`, createTranscript, token)
            .then((result) => {
                if (result.data.message === "success") {
                    if (createTranscript.ReceiptFile !== "") {
                        const formData = new FormData();
                        formData.append('ReceiptFile', createTranscript.ReceiptFile)
                        formData.append('entry_id', result.data.entry_id)
                        axios.patch(`${serverLink}students/progression/uploadfile/transcript`, formData).then((result) => {
                            const res = result.data.lastID

                        }).catch(err => {
                            console.error('ERROR', err);
                        });

                    }
                    toast.success("Record Submitted Successfully");
                    setIsFormLoading('off')
                    getTranscript();
                    document.getElementById("closeModal").click()
                    setCreateTranscript({
                        ...createTranscript,
                        studentID: props.loginData[0].StudentID,
                        ApplicationType: "",
                        ReceiptFile : "",
                        InstitutionName: "",
                        InstitutionEmail: "",
                        InstitutionPhoneNo: "",
                        InstitutionAddress: "",
                    });
                } else {
                    setIsFormLoading('off')
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading('off')
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    };

    useEffect(() => {
        getTranscript()
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Transcript"}
                items={["Transcript ", "Apply Transcript"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" />
                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-customer-table-toolbar="base"
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                >
                                    Apply Transcript
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <Table data={datatable} />
                    </div>
                </div>
                <Modal title={"APPLY TRANSCRIPT"}>
                    <TranscriptForm
                        onSubmit={onSubmit}
                        isFormLoading={isFormLoading}
                        onEdit={onEdit}
                        onFileChange={onFileChange}
                        handleReceiptFile={handleReceiptFile}
                        data={createTranscript}
                    />
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Transcript);
