import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import Modal from "../../common/modal/modal";
import Table from "../../common/table/table";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ServiceDeskForm from "./service-desk-form";
import DOMPurify from "dompurify";
import { projectshortCode } from "../../../resources/constants";

function ServiceDesk(props) {
  const token = props.loginData[0].token;
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState('off');
  const [complainType, setComplainType] = useState([])

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Complain Title",
        field: "ComplainTitle",
      },
      {
        label: "Description",
        field: "Description",
      },
      {
        label: "Status",
        field: "Status",
      },
      {
        label: "School Semester",
        field: "SemesterCode",
      },
      {
        label: "FilePath",
        field: "FilePath",
      },
    ],
    rows: [],
  });

  const [createComplain, setCreateComplain] = useState({
    studentID: props.loginData[0].StudentID,
    ComplainType: "",
    UploadThesis: "",
    ComplainTitle: "",
    Reason: "",
    UserType: "Student"
  });

  const studentID = props.loginData[0].StudentID

  const getData = async () => {
    axios.get(`${serverLink}students/progression/fetch/helpdesk/complain/type`, token).then((result) => {
      if (result.data.length > 0) {
        setComplainType(result.data)
      }
      setIsLoading(false)
    })
  }

  const getComplain = async () => {
    await axios
      .post(`${serverLink}students/progression/fetch/helpdesk/complain`, { studentID: studentID }, token)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((complain, index) => {
            rows.push({
              sn: index + 1,
              SemesterCode: complain.SemesterCode ?? "N/A",
              ComplainTitle: complain.ComplainTitle ?? "N/A",
              Description: <blockquote dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(complain.Description) }} />,
              FilePath: complain.FilePath === "" ? "---" :
                <a href={`${serverLink}public/uploads/${projectshortCode}/student_uploads/complain_uploads/${complain.FilePath}`}
                  rel="noreferrer" target={"_blank"}
                  className="btn btn-primary btn-sm">View</a>,
              Status: complain.Status ?? "N/A",

              action: (
                <button
                  className="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                  onClick={() =>
                    setCreateComplain({
                      SemesterCode: complain.SemesterCode,
                      ComplainTitle: complain.ComplainTitle,
                      Description: complain.Description,
                      FilePath: complain.FilePath,
                      Status: complain.Status
                    })
                  }
                >
                  <i className="fa fa-pen" />
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const onEdit = (e) => {
    setCreateComplain({
      ...createComplain,
      [e.target.id]: e.target.value,
    });
  };

  const onFileChange = (e) => {
    setCreateComplain({
      ...createComplain,
      [e.target.id]: e.target.files[0]
    })
  }

  const onReasonChange = (e) => {
    setCreateComplain({
      ...createComplain,
      "Reason": e
    })
  }

  const handleUploadThesis = (url) => {
    setCreateComplain({
      ...createComplain,
      UploadThesis: url,
    });
  }


  const onSubmit = async () => {

    if (createComplain.ComplainTitle.trim() === "") {
      showAlert("EMPTY FIELD", "Please enter complain title", "error");
      return false;
    }
    if (createComplain.ComplainType.trim() === "") {
      showAlert("EMPTY FIELD", "Please select complain type", "error");
      return false;
    }
    if (createComplain.Reason.trim() === "") {
      showAlert("EMPTY FIELD", "Please enter reason", "error");
      return false;
    }
    if (createComplain.Reason.length < 10) {
      showAlert("REASON IS TOO SHORT", "Your reason must contain a minimum of 10 characters", "error");
      return false;
    }

    setIsFormLoading('on')
    await axios
      .post(`${serverLink}students/progression/Submit/helpdesk/complain`, createComplain, token)
      .then((result) => {
        if (result.data.message === "success") {
          if (createComplain.UploadThesis !== "") {
            const formData = new FormData();
            formData.append('UploadThesis', createComplain.UploadThesis)
            formData.append('entry_id', result.data.entry_id)
            axios.patch(`${serverLink}students/progression/uploadfile/complain`, formData).then((result) => {
              const res = result.data.lastID

            }).catch(err => {
              console.error('ERROR', err);
            });

          }
          toast.success("Complain Submitted Successfully");
          setIsFormLoading('off')
          getComplain();
          document.getElementById("closeModal").click()
          setCreateComplain({
            ...createComplain,
            ComplainType: "",
            UploadThesis : "",
            UploadAttachment: "",
            ComplainTitle: "",
            Reason: "",
          });
        } else {
          setIsFormLoading('off')
          showAlert(
            "ERROR",
            "Something went wrong. Please try again!",
            "error"
          );
        }
      })
      .catch((error) => {
        setIsFormLoading('off')
        showAlert(
          "NETWORK ERROR",
          "Please check your connection and try again!",
          "error"
        );
      });

  };


  useEffect(() => {
    getData();
    getComplain()
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader
        title={"Service Desk"}
        items={["Portals", "Service Desk ", "Submit Complain"]}
      />
      <div className="flex-column-fluid">
        <div className="card">
          <div className="card-header border-0 pt-6">
            <div className="card-title" />
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                >
                  Log Complain
                </button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <Table data={datatable} />
          </div>
        </div>
        <Modal title={"LOG COMPLAIN"}>
          <ServiceDeskForm
            complainType={complainType}
            onSubmit={onSubmit}
            isFormLoading={isFormLoading}
            onEdit={onEdit}
            onFileChange={onFileChange}
            onReasonChange={onReasonChange}
            handleUploadThesis={handleUploadThesis}
            data={createComplain}
          />
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(ServiceDesk);
