import React from 'react';
import { Chart } from "react-google-charts";

function PerformanceCharts(props) {
    let data = [["Semesters", "Semesters"]];
    const gradePoint = props.gradePoint

    gradePoint.map(item => {
        data.push([item.SemesterCode, item.GradePoint])
    });

    // const gradePoint = props.gpa
    // gradePoint.map(item => {
    //     data.push([item.SemesterCode, item.GPA])
    // });

    const options = {
        title: "STUDENT PERFORMANCE GRAPH",
        curveType: 'function',
        legend: { position: 'bottom' }
    };

    return (
        <>
{
   gradePoint.length > 0 ?

            <div className='card card-flush h-xl-100 table responsive'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="fs-4 fw-bolder text-gray-900 text-center mb-5">Cumulative Grade Point Average (CGPA)
                            <div style={{ fontSize: '100px', opacity: '0.9' }}>
                                    <span>{props.cgpa}</span>
                                    {/* <span>{props.cgpa[0].CGPA}</span> */}

                            </div>
                        </div>
                    </div>
                    <div className='col-sm-9' style={{marginTop:"-8px"}}>
                        
                            <Chart
                            chartType="LineChart"
                            width="100%"
                            height="400px"
                            data={data}
                            options={options}
                        />
                        
                    </div>


                </div>
            </div>
            :<></>
                    }
        </>

    );
}
export default PerformanceCharts;