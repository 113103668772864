import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import Modal from "../../common/modal/modal";
import Table from "../../common/table/table";
import RegistrationComplainForm from "./resgistraion-complain-form";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import {EmailTemplates, formatDateAndTime, sendEmail} from "../../../resources/constants"
import DOMPurify from "dompurify";


function RegistrationComplain(props) {
  const token  = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [moduleList, setModuleList] = useState([]);
    
  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Student ID",
        field: "StudentID",
      },
      {
        label: "Smester Code",
        field: "SemesterCode",
      },
      {
        label: "Module Code",
        field: "ModuleCode",
      },
      {
        label: "Complain Type",
        field: "ComplainType",
      },
      {
        label: "Description",
        field: "Description",
      },
      {
        label: "Status",
        field: "Status",
      },
      {
        label: "InsertedDate",
        field: "InsertedDate",
      },
    ],
    rows: [],
  });

  const [createComplain, setCreateComplain] = useState({
    studentID: props.loginData[0].StudentID,
    ModuleCode: "",
    ComplainType: "",
    Description: "",
    });

  const studentID = props.loginData[0].StudentID
  const StudentName= props.loginData[0].StudentName
  const email = props.loginData[0].EmailAddress

  const ModuleCodeEdit = (e)=>{
    setCreateComplain({
      ...createComplain,
      ModuleCode: e
    })
    
    
  }

  const onReasonChange = (e) => {
    setCreateComplain({
        ...createComplain,
        "Description": e
    })
}

  const getData = async () => {
    axios.post(`${serverLink}students/registration/student/module`, { studentID: studentID }, token).then((result) => {
      let rows = [];
      result.data.length > 0 &&
      result.data.map((row) => {
          rows.push({ value: row.ModuleCode , label: row.ModuleName});
        });
      setModuleList(rows)
    })
    axios.post(`${serverLink}students/registration/fetch/registration/complain`, { studentID: studentID }, token).then((result) => {
    })
    setIsLoading(false)
  }

  const onEdit = (e) => {
    setCreateComplain({
        ...createComplain,
        [e.target.id]: e.target.value,
    });
};

const getComplain = async () => {
  await axios
      .post(`${serverLink}students/registration/fetch/registration/complain`, { studentID: studentID }, token)
      .then((result) => {
          if (result.data.length > 0) {
              let rows = [];
              result.data.map((complain, index) => {
                  rows.push({
                      sn: index + 1,
                      StudentID: complain.StudentID ?? "N/A",
                      SemesterCode: complain.SemesterCode ?? "N/A",
                      ModuleCode: complain.ModuleCode ??'N/A',
                      ComplainType: complain.ComplainType ?? "N/A",
                      Description: <blockquote dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(complain.Description)}}/>  ?? "N/A",
                      Status: complain.TheStatus === 'Applied' ? <p className="badge badge-primary">Applied</p>:  complain.TheStatus === 'Approved' ? <p className="badge badge-success">Approved</p> : <p className="badge badge-danger">Rejected</p> ?? "N/A",  
                      InsertedDate: formatDateAndTime(complain.InsertedDate, "date") ?? "N/A",
                      action: (
                          <button
                              className="btn btn-sm btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_general"
                              onClick={() =>
                                  setCreateComplain({
                                    StudentID: complain.StudentID,
                                    SemesterCode: complain.SemesterCode,
                                    ModuleCode: complain.ModuleCode,
                                    ComplainType: complain.ComplainType,
                                    Description: complain.Description,
                                    Status: complain.Status,
                                    InsertedDate: complain.InsertedDate,
                                  })
                              }
                          >
                              <i className="fa fa-pen" />
                          </button>
                      ),
                  });
              });

              setDatatable({
                  ...datatable,
                  columns: datatable.columns,
                  rows: rows,
              });
          }

          setIsLoading(false);
      })
      .catch((err) => {
          console.log("NETWORK ERROR");
      });
};

const onSubmit = async () => {
  let code = []
    createComplain.ModuleCode.map((x,y)=>{
      code.push(x.value)
    })
    const modules = code.toString().substring(1)

    const sendData={
    studentID: props.loginData[0].StudentID,
    ModuleCode: modules,
    ComplainType: createComplain.ComplainType,
    Description: createComplain.Description,
    }

    if (sendData.ComplainType.trim() === "") {
        showAlert("EMPTY FIELD", "Please select complain type", "error");
        return false;
    }
    if (sendData.ModuleCode.trim() === "") {
        showAlert("EMPTY FIELD", "Please select module name", "error");
        return false;
    }
    if (sendData.Description.trim() === "") {
        showAlert("EMPTY FIELD", "Please enter description", "error");
        return false;
    }
    if (sendData.Description.length < 20) {
      showAlert("DESCRIPTION IS TOO SHORT", "Your description must contain a minimum of 20 characters", "error");
      return false;
  }
   
        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/registration/submit/registration/complain`, sendData, token)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Registration Complain Submitted Successfully");
                    const mail = EmailTemplates('1', sendData)
                    sendEmail(email, mail.subject, mail.title, StudentName, mail.body, '')
                    setIsFormLoading('off')
                    getComplain();
                    document.getElementById("closeModal").click()
                    setCreateComplain({
                        ...createComplain,
                        StudentID: "",
                        ModuleCode: "",
                        ComplainType: "",
                        Description: "",
                    });
                } else if (result.data.message === "exist") {
                    showAlert("REGISTRATION COMPLAIN FAILED", "Sorry, you have an existing registration complain that has not been resolved!", "error").then(e=>{
                      document.getElementById("closeModal").click()
                      setIsFormLoading('off')
                      setCreateComplain({
                        ...createComplain,
                        StudentID: "",
                        ModuleCode: "",
                        ComplainType: "",
                        Description: "",
                    });
                    });
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    
};


useEffect(() => {
  getData();
  getComplain()
}, [])

    return isLoading ? (
      <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader
        title={"Registration Complain"}
        items={["Student Registration", "Registration", "Registration Complain"]}
      />
      <div className="flex-column-fluid">
        <div className="card">
          <div className="card-header border-0 pt-6">
            <div className="card-title" />
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                >
                  Log Complain
                </button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <Table data={datatable} />
          </div>
        </div>
        <Modal title={"REGISTRATION COMPLAIN"}>
        <RegistrationComplainForm data={createComplain} 
        moduleList={moduleList} 
        onSubmit={onSubmit} 
        isFormLoading={isFormLoading} 
        onEdit={onEdit} 
        ModuleCodeEdit={ModuleCodeEdit}
        onReasonChange={onReasonChange}
        />

        </Modal>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
      loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(RegistrationComplain);
