import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import Modal from "../../common/modal/modal";
import Table from "../../common/table/table";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { EmailTemplates, sendEmail } from "../../../resources/constants"
import DeferSemesterForm from "./defer-semester-form";
import DOMPurify from "dompurify";


function DeferSemester(props) {
  const token  = props.loginData[0].token;
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState('off');
  const [deferStatus, setDeferStatus] = useState([]);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Current Semester",
        field: "CurrentSemester",
      },
      {
        label: "Semesters Deferred",
        field: "NumberOfSemesters",
      },
      {
        label: "Return Semester",
        field: "ReturnSemester",
      },
      {
        label: "Reason",
        field: "Reason",
      },
      {
        label: "Deferment Status",
        field: "Status",
      },
    ],
    rows: [],
  });

  const [createDeferment, setCreateDeferment] = useState({
    studentID: props.loginData[0].StudentID,
    Semester: "",
    Reason: "",
    ReturnSemester: "",
  });

  const studentID = props.loginData[0].StudentID
  const StudentName = props.loginData[0].StudentName
  const email = props.loginData[0].EmailAddress


  const getData = async () => {
    axios.post(`${serverLink}students/deferment/check/deferment/status`, { studentID: studentID }, token).then((result) => {
      setDeferStatus(result.data.message)
    })
    setIsLoading(false)
  }

  const onReasonChange = (e) => {
    setCreateDeferment({
        ...createDeferment,
        "Reason": e
    })
}

  const onEdit = (e) => {
    setCreateDeferment({
      ...createDeferment,
      [e.target.id]: e.target.value
    })

    let numbers;
      let numbs;
      let code;
    if (e.target.id === "Semester") {
      if(parseInt(e.target.value) > 4){
        setCreateDeferment({
          ...createDeferment,
          Semester:""
        })
        showAlert("SEMESTER DEFERMENT ERROR","You can only defer maximum of 4 semesters","error")
        return;
      }
      numbers = e.target.value;
      numbs = props.currentSemester.substr(0, 2);
      code = props.currentSemester.substr(2)
      for (let i = 0; i < numbers; i++) {
        if (code == 'A') {
          code = 'B';
        } else if (code == 'B') {
          code = 'C';
        } else if (code == 'C') {
          code = 'A';
          numbs++;
        }
      }
      if(numbs + code !== NaN){
        setCreateDeferment({
          ...createDeferment,
          ReturnSemester: numbs + code,
          Semester:e.target.value
        })
      }
    }
    
  };

  const getDeferment = async () => {
    await axios
      .post(`${serverLink}students/deferment/fetch/deferment/all`, { studentID: studentID }, token)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((deferment, index) => {
            rows.push({
              sn: index + 1,
              CurrentSemester: deferment.CurrentSemester ?? "N/A",
              ReturnSemester: deferment.ReturnSemester ?? 'N/A',
              NumberOfSemesters: deferment.NumberOfSemesters ?? "N/A",
              ReturnSemester: deferment.ReturnSemester ?? "N/A",
              Reason: <blockquote dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(deferment.Reason)}}/>  ?? "N/A",
              Status: deferment.Status === 0 ? <p className="badge badge-primary">Submitted</p> : <p className="badge badge-success">Approved</p> ?? "N/A",
              action: (
                <button
                  className="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                  onClick={() =>
                    setCreateDeferment({
                      StudentID: deferment.StudentID,
                      CurrentSemester: deferment.CurrentSemester,
                      ReturnSemester: deferment.ReturnSemester,
                      NumberOfSemesters: deferment.NumberOfSemesters,
                      Reason: deferment.Reason,
                      Status: deferment.Status,
                    })
                  }
                >
                  <i className="fa fa-pen" />
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const onSubmit = async () => {
    if (createDeferment.Semester=== "") {
      showAlert("EMPTY FIELD", "Please enter No of Semester", "error");
      return false;
    }
    if (createDeferment.Semester >= 5) {
      showAlert("EMPTY FIELD", "You can only defer 4 semesters", "error");
      return false;
    }
    if (createDeferment.Reason.trim() === "") {
      showAlert("EMPTY FIELD", "Please enter description", "error");
      return false;
    }
    if (createDeferment.Reason.length < 20) {
      showAlert("DESCRIPTION IS TOO SHORT", "Your description must contain a minimum of 20 characters", "error");
      return false;
    }

    setIsFormLoading('on')
    await axios
      .post(`${serverLink}students/deferment/submit/deferment`, createDeferment, token)
      .then((result) => {
        if (result.data.message === "success") {
          toast.success("Deferment Submitted Successfully");
          const mail = EmailTemplates('3', createDeferment)
          sendEmail(email, mail.subject, mail.title, StudentName, mail.body, '')
          setIsFormLoading('off')
          getDeferment();
          setCreateDeferment({
            ...createDeferment,
            StudentID: "",
            Semester: "",
            Reason: "",
            ReturnSemester: "",
          });
          document.getElementById("closeModal").click()
        } else if (result.data.message === "exist") {
          showAlert("REGISTRATION COMPLAIN FAILED", "Sorry, you have an existing registration complain that has not been resolved!", "error").then(e => {
            setIsFormLoading('off')
            setCreateDeferment({
              ...createDeferment,
              StudentID: "",
              ActiveSemester: "",
              Semester: "",
              Reason: "",
              ReturnSemester: "",
              DefermentSemesters: ""
            });
            document.getElementById("closeModal").click()
          });
        } else {
          showAlert(
            "ERROR",
            "Something went wrong. Please try again!",
            "error"
          );
        }
      })
      .catch((error) => {
        showAlert(
          "NETWORK ERROR",
          "Please check your connection and try again!",
          "error"
        );
      });

  };


  useEffect(() => {
    getData();
    getDeferment()
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader
        title={"Student Deferment"}
        items={["Student Account", "Student Deferment"]}
      />
      <div className="flex-column-fluid">
        <div className="card">
          <div className="card-header border-0 pt-6">
            <div className="card-title" />
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                >
                  Defer Semester
                </button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <Table data={datatable} />
          </div>
        </div>
        <Modal title={"STUDENT DEFER SEMESTER"}>
          {
            <DeferSemesterForm
              data={createDeferment}
              onSubmit={onSubmit}
              isFormLoading={isFormLoading}
              onEdit={onEdit}
              deferStatus={deferStatus}
              onReasonChange={onReasonChange}
            />

          }
        </Modal>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
    currentSemester: state.currentSemester
  };
};

export default connect(mapStateToProps, null)(DeferSemester);
