import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../authentication/login/login";
import ForgotPassword from "../authentication/login/forgot-password";
import Accept from "../change-of-course/accept";
import Reject from "../change-of-course/reject";
import InterimTranscript from "../interim-transcript/interim-transcript";
import LoginFromStaff from "../authentication/login/login-from-staff";
import ResetPass from "../authentication/reset-password";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route exact element={<ResetPass/>} path='/reset-password/:token'  />
      <Route path="*" element={<Login />} />
      <Route path="/student/change-of-course/approve/:id" element={<Accept/>}/>
      <Route path="/student/change-of-course/reject/:id" element={<Reject/>}/>
      <Route path="/student/interim-transcript" element={<InterimTranscript/>}/>
      <Route path="/login-from-staff/:id" element={<LoginFromStaff/>}/>
    </Routes>
  );
}
