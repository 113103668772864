import React from "react";
import JoditEditor from "jodit-react";

export default function ChangeOfCourseForm(props) {
    return (
        <>
            <div className="form-group mb-4">
                <label htmlFor="NewCourse">Select New Program</label>
                <select
                    name="NewCourse"
                    id="NewCourse"
                    className="form-select"
                    onChange={props.onEdit}
                    value={props.data.NewCourse}
                >
                    <option value="">Select New Program...</option>
                   {
                    props.courseList.length > 0 &&props.courseList.map((x,y)=>{
                        return <option key={y} value={x.CourseCode}>{x.CourseName}</option>
                    })
                   }
                </select>
            </div>
            
            <div className="form-group mb-4">
                <label htmlFor="ParentName">Sponsor/Guardian Name</label>
                <input
                type="text"
                    name="ParentName"
                    id="ParentName"
                    className="form-control"
                    onChange={props.onEdit}
                    value={props.data.ParentName}
                />                           
                
            </div>
            <div className="form-group mb-4">
                <label htmlFor="ParentEmail">Sponsor/Guardian Email Address</label>
                <input
                type="text"
                    name="ParentEmail"
                    id="ParentEmail"
                    className="form-control"
                    onChange={props.onEdit}
                    value={props.data.ParentEmail}
                />                           
                
            </div>
            <div className="form-group mb-4">
                <label htmlFor="ParentEmail">Sponsor/Guardian Phone Number</label>
                <input
                type="text"
                    name="ParentPhone"
                    id="ParentPhone"
                    className="form-control"
                    onChange={props.onEdit}
                    value={props.data.ParentPhone}
                />                           
                
            </div>

            <div className="form-group mb-4 col-md-12">
                <label htmlFor="Reason">Change of Course Reason</label>
                <JoditEditor
                id="Reason"
                name="Reason"
                value={props.data.Reason}
                tabIndex={1}
                onChange={props.onReasonChange}
            />
            </div>
            <div className="form-group pt-2">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                </button>
            </div>

        </>
    )
}