import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { connect } from "react-redux";
import 'animate.css';
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { setHostelDetails } from "../../../actions/setactiondetails";
import { EmailTemplates, sendEmail } from "../../../resources/constants";
import { useNavigate } from "react-router";

function ViewRoom(props) {
    const token  = props.loginData[0].token;
    const navigate = useNavigate();
    const _id = props.hostelData.HostelID
    const [isLoading, setIsLoading] = useState(true);
    const [roomList, setRoomList] = useState([])
    const [bedList, setBedList] = useState([]);
    const [roomNumbers, setRoomNumbers] = useState([]);
    const [beds, setBeds] = useState([])

    const studentName= props.loginData[0].StudentName
    const email= props.loginData[0].EmailAddress


    const getData = async () => {
        axios.get(`${serverLink}students/hostel/get/hostel/floor/${_id}`, token).then((result) => {
            if (result.data.length > 0) {
                setRoomList(result.data);

            }
        })
        axios.get(`${serverLink}students/hostel/get/hostel/bed/list/${_id}`, token).then((result) => {
            if (result.data.length > 0) {
                setBeds(result.data)
            }
        })
        setIsLoading(false)

    }

    useEffect(() => {
        getData();
    }, [])

    const onEdit = (e) => {
        setReserveRoom({
            ...reserveRoom,
            [e.target.id]: e.target.value,

        });
        if (e.target.id === "FloorName") {
            let _floor = beds.filter(x => x.FloorName === e.target.value);
            setBedList(_floor)
            const RoomNumbers = [...new
                Set(beds.filter(x => x.FloorName === e.target.value).map(x => x.RoomNo))];
            setRoomNumbers(RoomNumbers);
            return;
        }
    };

    const [reserveRoom, setReserveRoom] = useState({
        FloorName: "",
    })

    const onSubmit = async (bed) => {
        props.setOnHostelDetails(bed)
        console.log(bed)
        if (bed.Status !== "Available") {
            showAlert("BED SPACE ERROR", "This bedspace is not available for reservation", "error");
            return;
        } else {
            toast.info("please wait...")
            let sendData = {
                studentID: props.loginData[0].StudentID,
                studentSemester: props.currentSemester,
                BedID: bed.BedID,
                RoomID: bed.RoomID,
                HostelID: bed.HostelID,
                BedNo: bed.BedNo,
                FloorName: bed.FloorName,
                RoomNo: bed.RoomNo
            }
            await axios.
                post(`${serverLink}students/hostel/submit/room/allocation`, sendData, token)
                .then((result) => {
                    if (result.data.message === 'exist') {
                        showAlert("BED SPACE ERROR", "you have already reserve bed space for this semester", "error")
                        setTimeout(() => {
                            navigate(`/student`)
                        }, 2000)
                    } else if (result.data.message === 'success') {
                        toast.success('Your room has been reserved successfully. Await approval from the Hostel Manager')
                        const mail = EmailTemplates('7', sendData)
                        sendEmail(email, mail.subject, mail.title, studentName, mail.body, '')
                        setTimeout(() => {
                            navigate(`/student/hostel/reserve-room`)
                        }, 3000)
                    } else {
                        showAlert(
                            "NETWORK ERROR",
                            "Spmething went wrong please try againlater!",
                            "error"
                        );
                    }
                }).catch((error) => {
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
            
        }
    };

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <div className="col-md-12">
                <label htmlFor="HostelFloor"><strong>Select Hostel Floor</strong></label>
                <div className="form-group mt-5 mb-5">
                    <select id="FloorName" onChange={onEdit}
                        value={reserveRoom.FloorName}
                        className="form-select"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                        <option value={""}>-select Floor-</option>
                        {roomList.length > 0 ?
                            <>
                                {roomList.map((x, y) => {
                                    return (
                                        <option key={y} value={x.FloorName}>{x.FloorName}</option>
                                    )
                                })}
                            </>
                            :
                            <></>}
                    </select>
                </div>

                {
                    roomNumbers.length > 0 &&
                    roomNumbers.map((z, k) => {
                        return (
                            <div className="col-md-12" key={k}>
                                <div className="row">
                                    <div className="col-xl-12 mb-3">
                                        <div className="card card-custom bg-gray-100 gutter-b card-stretch">
                                            <div className="card-header border-0 bg-dark py-5 animate__animated animate__heartBeat">
                                                <h3 className="card-title font-weight-bolder text-white">{z}
                                                </h3>
                                            </div>
                                            <div className="row col-md-12">
                                                <div className="d-flex flex-wrap flex-stack">
                                                    <div className="d-flex flex-column flex-grow-1">
                                                        <div className="d-flex flex-wrap">


                                                            {
                                                                bedList.length > 0 && bedList.filter(x => x.RoomNo === z).map((x, y) => {
                                                                    let Status; let badge;
                                                                    if (x.Status === 'Available') {
                                                                        Status = 'text-success';
                                                                        badge = 'badge-success'
                                                                    } else if (x.Status === 'Occupied') {
                                                                        Status = 'text-warning';
                                                                        badge = 'badge-warning'
                                                                    } else if (x.Status === 'Reserved') {
                                                                        Status = 'text-info';
                                                                        badge = 'badge-info'
                                                                    } else {
                                                                        Status = 'text-danger';
                                                                        badge = 'badge-danger'
                                                                    }
                                                                    return (

                                                                        <div key={y} className="border border-gray-500 border-dashed rounded m-4 animate__animated animate__bounceInRight">
                                                                            <div className="d-flex align-items-center">
                                                                                <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    onSubmit(x)
                                                                                }} className={`py-8 rounded-xl mt-3`}>
                                                                                    <span className={`fa fa-bed ${Status} fa-3x offset-1`}> {x.BedNo}</span>
                                                                                    <span className={`badge ${badge} offset-1`}>
                                                                                        {x.Status}</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>






                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }


            </div>

        </>




    )


}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        hostelData: state.HostelDetails,
        currentSemester: state.currentSemester
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHostelDetails: (p) => {
            dispatch(setHostelDetails(p));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRoom);