import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { useNavigate } from "react-router-dom"

function NewStudentRegistration(props) {
    const token = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [freshModule, setFreshModule] = useState([]);
    const [regSetting, setRegSetting] = useState([]);
    const [isRegistered, setIsRegistered] = useState(0);
    const [registeredModule, setRegisteredModule] = useState([]);
    const [creditLoad, setCreditLoad] = useState(0);
    const navigate = useNavigate();

    const getRecord = async () => {
        const sendData = {
            student_id: props.personalData.StudentID,
            course_code: props.personalData.CourseCode,
            student_level: props.personalData.StudentLevel,
            student_semester: props.personalData.StudentSemester,
        }
        await axios.post(`${serverLink}students/registration/new_student/register/module`, sendData, token)
            .then(res => {
                if (res.data.message === 'success') {
                    const data = res.data;
                    setIsRegistered(data.is_registered);
                    setFreshModule(data.is_module)
                    setRegSetting(data.reg_setting);
                    setIsLoading(false)
                } else {
                    toast.error('Error fetching your registration record. Please refresh your browser and try again!');
                }
            })
            .catch(err => {
                toast.error("NETWORK ERROR. Please refresh your browser and try again!")
            })
    }

    const handleChange = (data) => {
        const module = JSON.parse(data.target.getAttribute('data'));
        if (data.target.checked) {
            if (registeredModule.length > 0) {
                if ((creditLoad + parseInt(module.CreditLoad)) > regSetting[0].MaxCreditLoad) {
                    toast.error(`Registration failed. You've exceeded the maximum credit load allowed`);
                } else {
                    setRegisteredModule([
                        ...registeredModule, module
                    ]);
                    setCreditLoad(creditLoad + module.CreditLoad);
                    toast.success(`${module.ModuleName} has been added successfully`);
                }
            } else {
                setRegisteredModule([
                    ...registeredModule, module
                ]);
                setCreditLoad(parseInt(creditLoad+ module.CreditLoad));
                toast.success(`${module.ModuleName} has been added successfully`);
            }

        } else {
            const rm_module = registeredModule.filter(i => i.ModuleCode !== module.ModuleCode);
            setRegisteredModule(rm_module);
            setCreditLoad(creditLoad - parseInt(module.CreditLoad));
            toast.success(`${module.ModuleName} has been removed successfully`);
        }


    }


    const handleSubmit = async () => {
        const sendData = {
            modules: registeredModule,
            student_id: props.personalData.StudentID,
            student_level: props.personalData.StudentLevel,
            student_semester: props.personalData.StudentSemester
        };

        if (registeredModule.length < 1) {
            toast.error("Select your registration module(s) before submitting.");
            return false;
        }

        const is_final = props.personalData.StudentLevel === 'Internship';

        if (!is_final) {
            if (regSetting[0].MinCreditLoad > creditLoad) {
                toast.error("Sorry, you can't register below the minimum credit load allowed.");
                return false;
            }
        }

        await axios.post(`${serverLink}students/registration/new_student/registration`, sendData, token)
            .then(res => {
                if (res.data.message === 'success') {
                    showAlert('REGISTRATION SUCCESSFUL', 'Your semester registration was submitted successfully', 'success', 'Print').then(rec => {
                        if (rec) {
                            navigate("/registration/print-module")
                        }
                    })
                } else {
                    toast.error("Something went wrong submitting your registration. Please try again!!")
                }
            })
            .catch(err => {
                toast.error("NETWORK ERROR. Please try again!")
            })
    }


    useEffect(() => {
        getRecord();
    }, []);

    return isLoading ? <Loader /> : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"New Student Registration"}
                items={["Student Registration", "Registration", "New Student Registration"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-body pt-0">

                        {
                            props.personalData.Status !== 'active' &&
                            <div className="alert alert-danger" role="alert">
                                <h4 className="alert-heading">Status Error: {props.personalData.Status}</h4>
                                <p>You are currently not an active student. Please contact your department</p>
                            </div>
                        }

                        {
                            isRegistered > 0 &&
                            <div className="alert alert-danger" role="alert">
                                <h4 className="alert-heading">You've Already Registered</h4>
                                <p>Sorry, you've already registered for the current semester. For add /drop, please select the appropriate menu from the navigation bar. Alternately, you can contact your department for more information</p>
                            </div>
                        }


                        {
                            props.personalData.Status === 'active' && isRegistered === 0 &&
                            <>
                                <div className="row pt-5">
                                    <div className="col-md-3">
                                        <div className="table-responsive">
                                            <h3>Registration Settings</h3>
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <th>Min Credit</th>
                                                        <td>{regSetting[0].MinCreditLoad}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Max Credit</th>
                                                        <td>{regSetting[0].MaxCreditLoad}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="col-md-6">


                                        <div className="row">
                                            <h3>Fresh Module(s)</h3>
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Code</th>
                                                            <th>Title</th>
                                                            <th>Type</th>
                                                            <th>Credit</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            freshModule.length > 0 &&
                                                            freshModule.map((module, index) => {
                                                                const is_checked = registeredModule.filter(i => i.ModuleCode === module.ModuleCode).length > 0 ?? true;
                                                                return <tr key={index}>
                                                                    <td>{module.ModuleCode}</td>
                                                                    <td>{module.ModuleName}</td>
                                                                    <td>{module.ModuleType}</td>
                                                                    <td>{module.CreditLoad}</td>
                                                                    <td>
                                                                        <input type="checkbox" checked={is_checked} onChange={handleChange} data={JSON.stringify(module)} />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-12 text-center">
                                                <button className="btn btn-success btn-sm w-50" onClick={handleSubmit}>Submit</button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-3">
                                        <div className="table-responsive">
                                            <h3>Selected Module</h3>
                                            <div className="list-group">
                                                {
                                                    registeredModule.length > 0 ?
                                                        registeredModule.map((reg, index) => {
                                                            return <div key={index} className="list-group-item">
                                                                <h4 className="list-group-item-heading">{reg.ModuleTitle}</h4>
                                                                <span className="list-group-item-text">{reg.ModuleCode}</span>
                                                            </div>
                                                        }) : <p className="alert alert-info">Selected module(s) will show here</p>
                                                }

                                            </div>

                                            {
                                                registeredModule.length > 0 &&
                                                <h4 className="pt-5">Credit Load: {creditLoad}</h4>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        personalData: state.PersonalInfo,
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(NewStudentRegistration);
