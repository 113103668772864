import React, { useEffect, useState } from "react";
import PageHeader from "../common/pageheader/pageheader";
import Modal from "../common/modal/modal";
import Table from "../common/table/table";
import Loader from "../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import UploadPaymentForm from "./upload-payment-form";
import { currencyConverter, projectshortCode } from "../../resources/constants";

function UploadPayment(props) {
  const token = props.loginData[0].token;
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState('off');
  const [semesterList, setSemesterList] = useState([]);

  const updateCheckbox = (e) => {
    if (e.target.checked) {
      setCreatePayment({
        ...createPayment,
        [e.target.id]: e.target.value
      })
    } else {
      setCreatePayment({
        ...createPayment,
        [e.target.id]: ""
      })
    }

  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Semester Code",
        field: "SemesterCode",
      },
      {
        label: "Amount Paid",
        field: "AmountPaid",
      },
      {
        label: "Payment For",
        field: "PaymentFor",
      },
      {
        label: "File",
        field: "FilePath",
      },
    ],
    rows: [],
  });

  const [createPayment, setCreatePayment] = useState({
    studentID: props.loginData[0].StudentID,
    SchoolSemester: "",
    AmountPaid: "",
    PaymentFor: "",
    Document: "",
    Laundry: "",
    Feeding: "",
    SIWES: "",
    Hostel: "",
    Tuition: ""
  });

  const studentID = props.loginData[0].StudentID

  const getData = async () => {
    axios.get(`${serverLink}students/dashboard/fetch/all/semester`, token).then((result) => {
      if (result.data.length > 0) {
        setSemesterList(result.data);
      }

    })
    setIsLoading(false)
  }

  const getUploadPayment = async () => {
    await axios
      .post(`${serverLink}students/progression/fetch/upload/payment`, { studentID: studentID }, token)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((payment, index) => {
            rows.push({
              sn: index + 1,
              StudentID: payment.StudentID ?? "N/A",
              SemesterCode: payment.SemesterCode ?? "N/A",
              AmountPaid: currencyConverter(payment.AmountPaid) ?? 'N/A',
              PaymentFor: payment.PaymentFor ?? "N/A",
              FilePath: <a href={`${serverLink}public/uploads/${projectshortCode}/student_uploads/payment_uploads/${payment.FilePath}`} rel="noreferrer" target={"_blank"} className="btn btn-primary btn-sm">View</a> ?? "N/A",
              action: (
                <button
                  className="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                  onClick={() =>
                    setCreatePayment({
                      StudentID: payment.StudentID,
                      SemesterCode: payment.SemesterCode,
                      AmountPaid: payment.AmountPaid,
                      PaymentFor: payment.PaymentFor,
                      FilePath: <a href={payment.FilePath} rel="noreferrer" target={"_blank"} className="btn btn-primary btn-sm">View</a> ?? "N/A",
                    })
                  }
                >
                  <i className="fa fa-pen" />
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const onEdit = (e) => {
    setCreatePayment({
      ...createPayment,
      [e.target.id]: e.target.value,
    });
  };

  const handleDocument = (url) => {
    setCreatePayment({
      ...createPayment,
      Document: url,
    });
  }

  const onFileChange = (e) => {
    setCreatePayment({
      ...createPayment,
      [e.target.id]: e.target.files[0],
    });
  }

  const onSubmit = async () => {


    if (createPayment.SchoolSemester.trim() === "") {
      showAlert("EMPTY FIELD", "Please select Semester Code", "error");
      return false;
    }
    if (createPayment.AmountPaid.trim() === "") {
      showAlert("EMPTY FIELD", "Please enter Amount Paid", "error");
      return false;
    }

    if (createPayment.Laundry + createPayment.Feeding + createPayment.Hostel + createPayment.Tuition + createPayment.SIWES === "") {
      showAlert("EMPTY FIELD", "Please select Payment for", "error");
      return false;
    }
    if (createPayment.Document === "") {
      showAlert("EMPTY FIELD", "Please select file to upload", "error");
      return false;
    }

    setIsFormLoading('on')

    const formData = new FormData();
    formData.append("StudentID", props.loginData[0].StudentID);
    formData.append("SchoolSemester", createPayment.SchoolSemester);
    formData.append("PaymentFor", createPayment.Laundry + createPayment.Feeding + createPayment.Hostel + createPayment.Tuition + createPayment.SIWES);
    formData.append("Document", createPayment.Document);
    formData.append("AmountPaid", createPayment.AmountPaid)
    await axios
      .post(`${serverLink}students/progression/upload/payment`, formData, token)
      .then((result) => {
        if (result.data.message === "success") {
          toast.success("Payment Upload Submitted Successfully");
          setIsFormLoading('off')
          getUploadPayment();
          document.getElementById("closeModal").click()
          setCreatePayment({
            ...createPayment,
            studentID: "",
            SemesterCode: "",
            AmountPaid: "",
            PaymentFor: "",
            Document: "",
            Laundry: "",
            Feeding: "",
            SIWES: "",
            Hostel: "",
            Tuition: ""
          });
        } else {
          showAlert(
            "ERROR",
            "Something went wrong. Please try again!",
            "error"
          );
        }
      })
      .catch((error) => {
        showAlert(
          "NETWORK ERROR",
          "Please check your connection and try again!",
          "error"
        );
      });

  };


  useEffect(() => {
    getData();
    getUploadPayment()
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader
        title={"Upload Payment"}
        items={["Payment ", "Upload Payment"]}
      />
      <div className="flex-column-fluid">
        <div className="card">
          <div className="card-header border-0 pt-6">
            <div className="card-title" />
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                >
                  Upload Payment
                </button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <Table data={datatable} />
          </div>
        </div>
        <Modal title={"UPLOAD PAYMENT"}>
          <UploadPaymentForm
            semesterList={semesterList}
            data={createPayment}
            onSubmit={onSubmit}
            isFormLoading={isFormLoading}
            onEdit={onEdit}
            updateCheckbox={updateCheckbox}
            onFileChange={onFileChange}
            handleDocument={handleDocument}
          />
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(UploadPayment);
