import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import Loader from '../common/loader/loader';
import { connect } from 'react-redux';

const MyTimeTable = (props) => {
  const token  = props.loginData[0].token;
    const [loading, setisLoading] = useState(false)
    const [timetable, setTimetable] = useState([]);

    const studentID = props.loginData[0].StudentID

    const getData = async () => {
      axios.post(`${serverLink}students/dashboard/timetable/list`, { studentID: studentID }, token).then((result) => {
        setTimetable(result.data)
      })
  
      setisLoading(false)
    }
  
    useEffect(() => {
      getData();
    }, [])

    const days = [
        { id: '1', dayName: 'Monday' },
        { id: '2', dayName: 'Tuesday' },
        { id: '3', dayName: 'Wednesday' },
        { id: '4', dayName: 'Thursday' },
        { id: '5', dayName: 'Friday' },
        { id: '6', dayName: 'Saturday' },
    ]
    const t_id = parseInt(new Date().getDay());

    return loading ? (
    <Loader />
  ) : (
        <div className="card h-xl-100 col-md-12">
            <div className="card-header position-relative py-0 border-bottom-2">
                <h3 className="mt-7">My Timetable</h3>
                <ul className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3">
                    {
                        days.map((x, y) => {
                            const active = x.id === t_id.toString() ? 'show active' : ''
                            return (
                                <li className="nav-item p-0 ms-0 me-8" key={y}>
                                    <a className={`nav-link btn btn-color-muted px-0 ${active}`} data-bs-toggle="tab" href={`#kt_table_widget_7_tab_content_${x.id}`}>
                                        <span className="nav-text fw-bold fs-4 mb-3">{x.dayName}</span>
                                        <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="card-body">
                <div className="tab-content mb-2">
                    <div className="tab-pane fade" id="kt_table_widget_7_tab_content_1">
                        <div className="table-responsive">
                            {
                                timetable.length > 0 &&
                                timetable.filter(x => x.DayName === 'Monday').map((item, index) => {
                                    const classes = ["success", "danger", "info", "secondary", "primary", "default"];
                                    let random = "";
                                    for (let index = 0; index < timetable.length; index++) {
                                        
                                        random = Math.floor(Math.random() * classes.length);
                                    }
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-6"><span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${classes[random]}`} />
                                            <div className="flex-grow-1 me-5">
                                                <div className="text-gray-800 fw-bold fs-2">{item.StartTime} - {item.EndTime}<span className="text-gray-400 fw-bold fs-7"></span>
                                                </div>
                                                <div className="text-gray-700 fw-bold fs-6">{item.ModuleCode} : {item.ModuleName}</div>
                                                <div className="text-gray-400 fw-bold fs-7">Venue: {item.VenueName}  <span className='badge badge-secondary'>{item.ModuleType}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_table_widget_7_tab_content_2">
                        <div className="table-responsive">
                        {
                                timetable.length > 0 &&
                                timetable.filter(x => x.DayName === 'Tuesday').map((item, index) => {
                                    const classes = ["success", "danger", "info", "secondary", "primary", "default"];
                                    let random = "";
                                    for (let index = 0; index < timetable.length; index++) {
                                        
                                        random = Math.floor(Math.random() * classes.length);
                                    }
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-6"><span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${classes[random]}`} />
                                            <div className="flex-grow-1 me-5">
                                                <div className="text-gray-800 fw-bold fs-2">{item.StartTime} - {item.EndTime}<span className="text-gray-400 fw-bold fs-7"></span>
                                                </div>
                                                <div className="text-gray-700 fw-bold fs-6">{item.ModuleCode} : {item.ModuleName}</div>
                                                <div className="text-gray-400 fw-bold fs-7">Venue: {item.VenueName}  <span className='badge badge-secondary'>{item.ModuleType}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade show active" id="kt_table_widget_7_tab_content_3">
                        <div className="table-responsive">
                        {
                                timetable.length > 0 &&
                                timetable.filter(x => x.DayName === 'Wednesday').map((item, index) => {
                                    const classes = ["success", "danger", "info", "secondary", "primary", "default"];
                                    let random = "";
                                    for (let index = 0; index < timetable.length; index++) {
                                        
                                        random = Math.floor(Math.random() * classes.length);
                                    }
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-6"><span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${classes[random]}`} />
                                            <div className="flex-grow-1 me-5">
                                                <div className="text-gray-800 fw-bold fs-2">{item.StartTime} - {item.EndTime}<span className="text-gray-400 fw-bold fs-7"></span>
                                                </div>
                                                <div className="text-gray-700 fw-bold fs-6">{item.ModuleCode} : {item.ModuleName}</div>
                                                <div className="text-gray-400 fw-bold fs-7">Venue: {item.VenueName}  <span className='badge badge-secondary'>{item.ModuleType}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_table_widget_7_tab_content_4">
                        <div className="table-responsive">
                        {
                                timetable.length > 0 &&
                                timetable.filter(x => x.DayName === 'Thursday').map((item, index) => {
                                    const classes = ["success", "danger", "info", "secondary", "primary", "default"];
                                    let random = "";
                                    for (let index = 0; index < timetable.length; index++) {
                                        
                                        random = Math.floor(Math.random() * classes.length);
                                    }
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-6"><span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${classes[random]}`} />
                                            <div className="flex-grow-1 me-5">
                                                <div className="text-gray-800 fw-bold fs-2">{item.StartTime} - {item.EndTime}<span className="text-gray-400 fw-bold fs-7"></span>
                                                </div>
                                                <div className="text-gray-700 fw-bold fs-6">{item.ModuleCode} : {item.ModuleName}</div>
                                                <div className="text-gray-400 fw-bold fs-7">Venue: {item.VenueName}  <span className='badge badge-secondary'>{item.ModuleType}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_table_widget_7_tab_content_5">
                        <div className="table-responsive">
                        {
                                timetable.length > 0 &&
                                timetable.filter(x => x.DayName === 'Friday').map((item, index) => {
                                    const classes = ["success", "danger", "info", "secondary", "primary", "default"];
                                    let random = "";
                                    for (let index = 0; index < timetable.length; index++) {
                                        
                                        random = Math.floor(Math.random() * classes.length);
                                    }
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-6"><span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${classes[random]}`} />
                                            <div className="flex-grow-1 me-5">
                                                <div className="text-gray-800 fw-bold fs-2">{item.StartTime} - {item.EndTime}<span className="text-gray-400 fw-bold fs-7"></span>
                                                </div>
                                                <div className="text-gray-700 fw-bold fs-6">{item.ModuleCode} : {item.ModuleName}</div>
                                                <div className="text-gray-400 fw-bold fs-7">Venue: {item.VenueName}  <span className='badge badge-secondary'>{item.ModuleType}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="tab-pane fade" id="kt_table_widget_7_tab_content_6">
                        <div className="table-responsive">
                        {
                                timetable.length > 0 &&
                                timetable.filter(x => x.DayName === 'Saturday').map((item, index) => {
                                    const classes = ["success", "danger", "info", "secondary", "primary", "default"];
                                    let random = "";
                                    for (let index = 0; index < timetable.length; index++) {
                                        
                                        random = Math.floor(Math.random() * classes.length);
                                    }
                                    return (
                                        <div key={index} className="d-flex align-items-center mb-6"><span data-kt-element="bullet" className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-${classes[random]}`} />
                                            <div className="flex-grow-1 me-5">
                                                <div className="text-gray-800 fw-bold fs-2">{item.StartTime} - {item.EndTime}<span className="text-gray-400 fw-bold fs-7"></span>
                                                </div>
                                                <div className="text-gray-700 fw-bold fs-6">{item.ModuleCode} : {item.ModuleName}</div>
                                                <div className="text-gray-400 fw-bold fs-7">Venue: {item.VenueName}  <span className='badge badge-secondary'>{item.ModuleType}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/*end: Card Body*/}
        </div>
    )

}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(MyTimeTable);
