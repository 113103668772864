import React from "react";
import JoditEditor from "jodit-react";
import SimpleFileUpload from 'react-simple-file-upload';
import { projectshortCode, simpleFileUploadAPIKey } from '../../../resources/constants'

export default function ServiceDeskForm(props) {
    return (
        <>

<div className="form-group mb-4">
                <label htmlFor="ComplainTitle">Complain Title</label>
                <input
                type={"text"}
                id="ComplainTitle"
                name="ComplainTitle"
                className="form-control"
                placeholder="Enter your Complain Title"
                onChange={props.onEdit}
                value={props.data.ComplainTitle}

                   /> 
            </div>
            <div className="form-group mb-4">
                <label htmlFor="ComplainType">Complain Type</label>
                <select
                    name="ComplainType"
                    id="ComplainType"
                    className="form-select"
                    onChange={props.onEdit}
                    value={props.data.ComplainType}
                >
                    <option value="">Complain Type...</option>
                    {
                        props.complainType.length > 0 && props.complainType.map((complain, index) => {
                            return <option key={index} value={complain.EntryID}> {complain.ComplainType}</option>
                        })
                    }
                </select>
            </div>

            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="">Upload File (PDF File)</span>
                </label>
                {/* <SimpleFileUpload
                    apiKey={simpleFileUploadAPIKey}
                    tag={`${projectshortCode}-service-desk`}
                    onSuccess={props.handleUploadThesis}
                    preview="false"
                    width="100%"
                    height="100"
                /> */}
                <input type="file" id="UploadThesis" className="form-control" onChange={props.onFileChange} />
            </div>

            <div className="form-group mb-4 col-md-12">
                <label htmlFor="Reason">Complain Reason</label>
                <JoditEditor
                id="Reason"
                name="Reason"
                tabIndex={1}
                onChange={props.onReasonChange}
                value={props.data.Reason}
            />
            </div>
            <div className="form-group pt-2">
                 <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                </button>
                
            </div>

        </>
    )
}