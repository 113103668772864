import React from "react";

export default function PaymentRollOverForm(props) {
    return (
        <>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <label className="fw-bolder fs-6">Payment ID: {props.data.PaymentID}</label>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="fw-bolder fs-6">Amount Paid: {props.data.AmountPaid}</label>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="fw-bolder fs-6">Payment Method: {props.data.PaymentMethod}</label>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="fw-bolder fs-6">Semester Code: {props.data.SemesterCode}</label>
                    </div>
                </div>
                <div className="form-group mt-4">
                    <label className="fs-6">Reason</label>
                    <textarea className="form-control" onChange={props.onEdit} name="Reason" id="Reason" cols={'10'} rows="5"></textarea>
                </div>
                <div className="form-group mt-5">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Rollover Payment</span>
                    <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                    </span>
                </button>
                </div>
            </div>

        </>
    )
}