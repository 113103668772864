import React, {useEffect, useState} from "react";
import PageHeader from "../../common/pageheader/pageheader";
import Loader from "../../common/loader/loader";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {formatDateAndTime} from "../../../resources/constants";
import {useNavigate} from "react-router-dom"

function Registration(props) {
  const token  = props.loginData[0].token;
  const [isLoading, setIsLoading] = useState(true);
  const [freshModule, setFreshModule] = useState([]);
  const [carryOverModule, setCarryOverModule] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [registeredModuleTimetable, setRegisteredModuleTimetable] = useState([])
  const [regSetting, setRegSetting] = useState([]);
  const [isRegistered, setIsRegistered] = useState(0);
  const [isCleared, setIsCleared] = useState(0);
  const [registeredModule, setRegisteredModule] = useState([]);
  const [creditLoad, setCreditLoad] = useState(0);
  const [currentState, setCurrentState] = useState("");
  const navigate = useNavigate();


  const getRecord = async () => {
      const sendData = {
          student_id: props.personalData.StudentID,
          course_code: props.personalData.CourseCode,
          student_level: props.personalData.StudentLevel,
          student_semester: props.personalData.StudentSemester,
      }
    await axios.post(`${serverLink}students/registration/registration/meta`, sendData, token)
        .then(res => {
            if (res.data.message === 'success') {
                const data = res.data;
                setRegSetting(data.reg_setting);
                setIsRegistered(data.is_registered);
                setIsCleared(data.is_cleared);

                const fresh_timetable = data.fresh_timetable;
                const carry_over_timetable = data.carry_over_timetable;
                const timetable_data = fresh_timetable.concat(carry_over_timetable);

                let timetable_unique = [];
                timetable_data.map(tt => {
                    const check = timetable_unique.filter(i => i.DayName === tt.DayName && i.ModuleCode === tt.ModuleCode && i.StartTime === tt.StartTime && i.EndTime === tt.EndTime);
                    if (check.length < 1)
                        timetable_unique.push(tt)
                })

                const fresh_module = data.fresh;
                let fresh_row = [];
                if (fresh_module.length > 0) {
                    fresh_module.map(fresh => {
                        const check = timetable_unique.filter(t => t.ModuleCode === fresh.ModuleCode);
                        if (check.length > 0)
                            fresh_row.push(fresh);
                    })
                }

                const carry_over = data.carry_over;
                let carry_over_row = [];
                if (carry_over.length > 0) {
                    carry_over.map(carry => {
                        const check = timetable_unique.filter(t => t.ModuleCode === carry.ModuleCode);
                        if (check.length > 0)
                            carry_over_row.push(carry);
                    })
                }

                setCurrentState(carry_over_row.length > 0 ? 'carry_over' : 'fresh')
                setFreshModule(fresh_row);
                setCarryOverModule(carry_over_row);
                setTimetable(timetable_unique);
                setIsLoading(false)
            } else {
                showAlert('NO RECORD', 'Error fetching your registration record. Please refresh your browser and try again!', 'error');
            }
        })
        .catch(err => {
            toast.error("NETWORK ERROR. Please refresh your browser and try again!")
        })
  }

  const handleChange = (data) => {
      const module = JSON.parse(data.target.getAttribute('data'));
      if (data.target.checked) {
          const selected_module_timetable = timetable.filter(i => i.ModuleCode === module.ModuleCode);

          if (registeredModule.length > 0) {
              let conflict_check = false;
              selected_module_timetable.map(tt => {
                  const start_time1 = tt.StartTime;
                  const end_time1 = tt.EndTime;
                  const s_m_day = tt.DayName;

                  registeredModuleTimetable.map(reg => {
                      const start_time2 = reg.StartTime;
                      const end_time2 = reg.EndTime;
                      const r_m_day = reg.DayName;

                      if (r_m_day === s_m_day) {
                        if ((start_time1 > start_time2 && start_time1 < end_time2) || (start_time2 > start_time1 && start_time2 < end_time1)) {
                              toast.error(`${module.ModuleCode}'s timetable is clashing with the ${reg.ModuleCode}'s timetable`)
                              conflict_check = true
                          }
                      }
                  })
              });

              if (!conflict_check) {
                if ((creditLoad + parseInt(module.Credit)) > regSetting[0].MaxCreditLoad ) {
                    toast.error(`Registration failed. You've exceeded the maximum credit load allowed`);
                } else {
                    setRegisteredModule([
                        ...registeredModule, module
                    ]);
                    setRegisteredModuleTimetable([
                        ...registeredModuleTimetable, selected_module_timetable
                    ])
                    setCreditLoad(prevState => prevState+parseInt(module.Credit));
                    toast.success(`${module.ModuleTitle} has been added successfully`);
                }
              }
          } else {
              setRegisteredModule([
                  ...registeredModule, module
              ]);
              setRegisteredModuleTimetable([
                  ...registeredModuleTimetable, selected_module_timetable
              ])
              setCreditLoad(prevState => prevState+parseInt(module.Credit));
              toast.success(`${module.ModuleTitle} has been added successfully`);
          }

      } else {
          const rm_module = registeredModule.filter(i => i.ModuleCode !== module.ModuleCode);
          const rm_module_timetable = registeredModuleTimetable.filter(i => i.ModuleCode !== module.ModuleCode);
          setRegisteredModule(rm_module);
          setRegisteredModuleTimetable(rm_module_timetable);
          setCreditLoad(prevState => prevState-parseInt(module.Credit));
          toast.success(`${module.ModuleTitle} has been removed successfully`);
      }


  }

  const onNext = (state) => {
    if (state === 'to_fresh') {
        const unregistered_carry_over = [];
        if (carryOverModule.length > 0) {
            carryOverModule.map(mod => {
                if (registeredModule.filter(i => i.ModuleCode === mod.ModuleCode).length < 1)
                unregistered_carry_over.push(mod)
            })

            if (unregistered_carry_over.length === 0) {
                setCurrentState('fresh');
            } else {
                toast.error("Please the carryover module before moving to the next stage");
            }
        } else {
            setCurrentState('fresh'); 
        }
        
    }
    else if (state === 'to_carry_over')
        setCurrentState('carry_over');
  }

  const handleSubmit = async () => {
      const sendData = {
          modules: registeredModule,
          student_id: props.personalData.StudentID,
          student_level: props.personalData.StudentLevel,
          student_semester: props.personalData.StudentSemester
      };

      if (registeredModule.length < 1) {
          toast.error("Select your registration module(s) before submitting.");
          return false;
      }

      const is_final = props.personalData.StudentLevel === 'Internship';

      if (!is_final) {
          registeredModule.map(rg => {
              if (rg.ModuleTitle.includes('Project')) {
                  is_final = true;
              }
          })
      }

      if (!is_final) {
          if (regSetting[0].MinCreditLoad > creditLoad) {
              toast.error("Sorry, you can't register below the minimum credit load allowed.");
              return false;
          }
      }

      await axios.post(`${serverLink}students/registration/registration/register`, sendData, token)
          .then(res => {
              if (res.data.message === 'success') {
                  showAlert('REGISTRATION SUCCESSFUL', 'Your semester registration was submitted successfully', 'success', 'Print').then(rec => {
                      if (rec) {
                          navigate("/registration/print-module")
                      }
                  })
              } else if (res.data.message === 'registered') {
                  toast.error("Ooops, you've already registered this semester!!!")
              } else {
                  toast.error("Something went wrong submitting your registration. Please try again!!")
              }
          })
          .catch(err => {
              toast.error("NETWORK ERROR. Please try again!")
          })
  }


  useEffect(() => {
      getRecord();
  },[]);

  return isLoading ? <Loader /> : (
      <div className="d-flex flex-column flex-row-fluid">
        <PageHeader
            title={"Semester Registration"}
            items={["Student Registration", "Registration", "Semester Registration"]}
        />
        <div className="flex-column-fluid">
          <div className="card">
            <div className="card-body pt-0">

                {
                    props.personalData.Status !== 'active' &&
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Status Error: {props.personalData.Status}</h4>
                        <p>You are currently not an active student. Please contact your department</p>
                    </div>
                }

                {
                    isCleared === 0 &&
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Finance Clearance Error</h4>
                        <p>You can't register without finance clearance. Please clear with the finance</p>
                    </div>
                }

                {
                    regSetting.length === 0 &&
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Registration Period Expired</h4>
                        <p>No active registration for the current semester. Please contact your department</p>
                    </div>
                }

                {
                    isRegistered > 0 &&
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">You've Already Registered</h4>
                        <p>Sorry, you've already registered for the current semester. For add /drop, please select the appropriate menu from the navigation bar. Alternately, you can contact your department for more information</p>
                    </div>
                }

                {
                    freshModule.length === 0 && carryOverModule.length === 0 &&
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">No Module Found</h4>
                        <p>No running module found your course, level and semester. Please contact your department</p>
                    </div>
                }

                {
                    props.personalData.Status=== 'active' && isCleared !== 0 && regSetting.length !== 0 && (freshModule.length > 0 || carryOverModule.length > 0) && isRegistered === 0 &&
                        <>
                            <div className="row pt-5">
                                <div className="col-md-3">
                                    <div className="table-responsive">
                                        <h3>Registration Settings</h3>
                                        <table className="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>Min Credit</th>
                                                    <td>{regSetting[0].MinCreditLoad}</td>
                                                </tr>
                                                <tr>
                                                    <th>Max Credit</th>
                                                    <td>{regSetting[0].MaxCreditLoad}</td>
                                                </tr>
                                                <tr>
                                                    <th>Min Spill Over</th>
                                                    <td>{regSetting[0].MinSpillOver}</td>
                                                </tr>
                                                <tr>
                                                    <th>Reg Start</th>
                                                    <td>{formatDateAndTime(regSetting[0].StartDate, 'date')}</td>
                                                </tr>
                                                <tr>
                                                    <th>Reg End</th>
                                                    <td>{formatDateAndTime(regSetting[0].EndDate, 'date')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    {
                                        currentState === 'carry_over' ?
                                            <div className="row">
                                                <h3>Carry Over Module(s)</h3>
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>S/N</th>
                                                            <th>Code</th>
                                                            <th>Title</th>
                                                            <th>Type</th>
                                                            <th>Credit</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            carryOverModule.length > 0 &&
                                                                carryOverModule.map((module, index) => {
                                                                    module.Status = 'RM';

                                                                    const is_checked = registeredModule.filter(i => i.ModuleCode === module.ModuleCode).length > 0 ?? true;
                                                                    return <tr key={index}>
                                                                        <td>{(index+1)}</td>
                                                                        <td>{module.ModuleCode}</td>
                                                                        <td>{module.ModuleTitle}</td>
                                                                        <td>{module.ModuleType}</td>
                                                                        <td>{module.Credit}</td>
                                                                        <td>
                                                                            <input type="checkbox" checked={is_checked} onChange={handleChange} data={JSON.stringify(module)}/>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-12">
                                                    <button className="btn btn-primary btn-sm w-100" onClick={() => onNext('to_fresh')}>Next</button>
                                                </div>
                                            </div>
                                            : 
                                            <div className="row">
                                                    <h3>Fresh Module(s)</h3>
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>Code</th>
                                                                <th>Title</th>
                                                                <th>Type</th>
                                                                <th>Credit</th>
                                                                <th>Action</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                freshModule.length > 0 &&
                                                                freshModule.map((module, index) => {
                                                                    module.Status = 'Fresh';
                                                                    const check = carryOverModule.filter(i => i.ModuleCode === module.ModuleCode);

                                                                    if (check.length < 1) {
                                                                        const is_checked = registeredModule.filter(i => i.ModuleCode === module.ModuleCode).length > 0 ?? true;
                                                                        return <tr key={index}>
                                                                            <td>{module.ModuleCode}</td>
                                                                            <td>{module.ModuleTitle}</td>
                                                                            <td>{module.ModuleType}</td>
                                                                            <td>{module.Credit}</td>
                                                                            <td>
                                                                                <input type="checkbox" checked={is_checked} onChange={handleChange} data={JSON.stringify(module)}/>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    
                                                                })
                                                            }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button className="btn btn-primary btn-sm w-50" onClick={() => onNext('to_carry_over')}>Previous</button>
                                                        <button className="btn btn-success btn-sm w-50" onClick={handleSubmit}>Submit</button>
                                                    </div>
                                                </div>
                                    }
                                </div>

                                <div className="col-md-3">
                                    <div className="table-responsive">
                                        <h3>Selected Module</h3>
                                        <div className="list-group">
                                            {
                                                registeredModule.length > 0 ?
                                                    registeredModule.map((reg, index) => {
                                                        return <div key={index} className="list-group-item">
                                                            <h4 className="list-group-item-heading">{reg.ModuleTitle}</h4>
                                                            <span className="list-group-item-text">{reg.ModuleCode}</span> | <span className="list-group-item-text">Credit: {reg.Credit}</span> | <span className="list-group-item-text">Credit: {reg.Status}</span>
                                                        </div>
                                                    }) : <p className="alert alert-info">Selected module(s) will show here</p>
                                            }

                                        </div>

                                        {
                                            registeredModule.length > 0 &&
                                            <h4 className="pt-5">Credit Load: {creditLoad}</h4>
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
          </div>
        </div>
      </div>
  );
}

const mapStateToProps = (state) => {
    return {
        personalData: state.PersonalInfo,
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Registration);
