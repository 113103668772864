import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { serverLink } from "../../resources/url";
import Loader from "../common/loader/loader";
import Modal from "../common/modal/modal";
import PageHeader from "../common/pageheader/pageheader";
// import ReportTable from "../common/table/report_table";
import Table from "../common/table/table";


function ResultComplain(props) {
  const token  = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const studentID = props.loginData[0].StudentID;
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "ModuleCode",
                field: "ModuleCode",
            },
            {
                label: "ModuleDescription",
                field: "ModuleTitle",
            },
            {
                label: "ComplainType",
                field: "ComplainType",
            },
            {
                label: "ComplainOn",
                field: "ComplainOn",
            },
            {
                label: "Semester",
                field: "SchoolSemester",
            },
            {
                label: "Remark",
                field: "Remark",
            },
            {
                label: "ResolvedBy",
                field: "ResolvedBy",
            },
            {
                label: "ResolvedOn",
                field: "ResolvedOn",
            }

        ],
        rows: [],
    });

    const [resultComplain, setResultComplain] = useState({
        ModuleCode: "",
        EntryID: "",
        ModuleTitle: "",
        SemesterCode: "",
        SelectedSemesterCode: "",
        ComplaintType: "",
        ComplaintMessage: "",
        InsertedBy: props.loginData[0].StudentID,

    })
    const [semeterList, setsemesterList] = useState([]);
    const [data, setData] = useState([]);
    const getResults = async () => {
        await axios.get(`${serverLink}students/registration/module/semester/list`, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setsemesterList(result.data)
                }
                setIsLoading(false)
            })

        await axios.post(`${serverLink}students/registration/module/result/complain/list`, { StudentID: studentID }, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setData(result.data);
                   getTableData(result.data)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };
    const getTableData = (rows__) => {
        let rows = [];
        rows__.map((item, index) => {
            rows.push({
                sn: index + 1,
                ModuleCode: item.ModuleCode,
                ModuleTitle: item.ModuleName,
                ComplainType: item.ComplainType,
                ComplainOn: item.InsertedOn,
                SchoolSemester: item.SchoolSemester,
                Remark: item.Remark,
                ResolvedBy: item.ResolvedBy,
                ResolvedOn: item.ResolvedOn
            });
        });
        setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
        });
        setIsLoading(false);
    }

 
    const onEdit = (e) => {
        if (e.target.id === "SelectedSemesterCode") {
            setResultComplain({
                ...resultComplain,
                [e.target.id]: e.target.value
            })
            let filtered = data.filter(x => x.SchoolSemester === e.target.value)
            getTableData(filtered)
        } else {
            setResultComplain({
                ...resultComplain,
                [e.target.id]: e.target.value
            })
        }

    }
    useEffect(() => {
        getResults();
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Result Complains"}
                items={["Student Result", "Result Complains"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-body pt-0">
                        <div className="col-md-12 mt-4">
                            <select id="SelectedSemesterCode" onChange={onEdit}
                                value={resultComplain.SelectedSemesterCode}
                                className="form-select"
                                data-kt-select2="true"
                                data-placeholder="Select option"
                                data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                <option value={""}>-select semester-</option>
                                {semeterList.length > 0 ?
                                    <>
                                        {semeterList.map((x, y) => {
                                            return (
                                                <option key={y} value={x.SemesterCode}>{x.SemesterName}</option>
                                            )
                                        })}
                                    </>
                                    :
                                    <></>}
                            </select>
                        </div>
                        <Table data={datatable} />

                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ResultComplain);

