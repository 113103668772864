import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./navigation.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
function Navigation(props) {
  const navigation = useNavigate();
  return (
    <>
      {/*begin::Tabs container*/}
      <div className="container-custom container-xxl d-lg-flex flex-column w-100 mt-1">
        {/*begin::Header tabs*/}
        <div
          className="header-tabs d-flex align-items-stretch w-100 h-60px h-lg-100px overflow-auto mb-5 mb-lg-0"
          id="kt_header_tabs"
        >
          <ul className="nav nav-stretch flex-nowrap w-100 h-100">
            <li className="nav-item flex-equal">
              <a
                className="nav-link d-flex flex-column text-nowrap flex-center w-100 active"
                data-bs-toggle="tab"
                href="#kt_header_navs_tab_1"
              >
                <span className="text-uppercase text-dark fw-bolder fs-6 fs-lg-5">
                  STUDENT REGISTRATION
                </span>
                {/* <span className="text-gray-500 fs-8 fs-lg-7">
                  Faculty, Department, Courses ...
                </span> */}
              </a>
            </li>
            <li className="nav-item flex-equal">
              <a
                className="nav-link d-flex flex-column text-nowrap flex-center w-100"
                data-bs-toggle="tab"
                href="#kt_header_navs_tab_2"
              >
                <span className="text-uppercase text-dark fw-bolder fs-6 fs-lg-5">
                  STUDENT RESULT
                </span>
              </a>
            </li>

            <li className="nav-item flex-equal">
              <a
                className="nav-link d-flex flex-column text-nowrap flex-center w-100"
                data-bs-toggle="tab"
                href="#kt_header_navs_tab_3"
              >
                <span className="text-uppercase text-dark fw-bolder fs-6 fs-lg-5">
                  SEMESTER ASSESSMENT
                </span>
              </a>
            </li>
            <li className="nav-item flex-equal">
              <a
                className="nav-link d-flex flex-column text-nowrap flex-center w-100"
                data-bs-toggle="tab"
                href="#kt_header_navs_tab_4"
              >
                <span className="text-uppercase text-dark fw-bolder fs-6 fs-lg-5">
                  STUDENT ACCOUNT
                </span>
              </a>
            </li>
            <li className="nav-item flex-equal">
              <a
                className="nav-link d-flex flex-column text-nowrap flex-center w-100"
                data-bs-toggle="tab"
                href="#kt_header_navs_tab_5"
              >
                <span className="text-uppercase text-dark fw-bolder fs-6 fs-lg-5">
                  PORTALS
                </span>
              </a>
            </li>
            {/* {
              props.loginData[0].StudentLevel > 500 ?
              <li className="nav-item flex-equal">
              <a
                className="nav-link d-flex flex-column text-nowrap flex-center w-100"
                data-bs-toggle="tab"
                href="#kt_header_navs_tab_6"
              >
                <span className="text-uppercase text-dark fw-bolder fs-6 fs-lg-5">
                  My Final Year
                </span>
              </a>
            </li>:<></>
            } */}
            
          </ul>
        </div>
        {/*end::Header tabs*/}
      </div>
      {/*end::Tabs container*/}
      {/*begin::Header panel*/}
      <div
        className="d-flex align-items-stretch h-lg-70px"
        data-kt-sticky="true"
        data-kt-sticky-name="header-tabs"
        data-kt-sticky-offset="{default: 'false', lg: '300px'}"
        data-kt-sticky-dependencies="#kt_wrapper"
        data-kt-sticky-class="fixed-top bg-body shadow-sm border-0"
      >
        {/*begin::Panel container*/}
        <div className="container-custom container-xxl d-lg-flex flex-column w-100">
          {/*begin::Header navs*/}
          <div
            className="header-navs d-lg-flex flex-column justify-content-lg-center h-100 w-100"
            id="kt_header_navs_wrapper"
          >
            {/*begin::Header tab content*/}
            <div
              className="tab-content"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: true, lg: false}"
              data-kt-scroll-height="auto"
              data-kt-scroll-offset="70px"
            >
              {/*begin::Tab panel*/}
              <div
                className="tab-pane fade active show"
                id="kt_header_navs_tab_1"
              >
                {/*begin::Menu wrapper*/}
                <div className="header-menu d-flex flex-column align-items-start flex-lg-row">
                  {/*begin::Menu*/}
                  <Nav>
                    <NavDropdown
                      title={<span className="fw-bold fs-6">Registration</span>}
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/registration/new-student-registration");
                        }}
                      >
                        New Student Registration
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/registration/registration");
                        }}
                      >
                        Semester Registration
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/registration/registration-complain");
                        }}
                      >
                        Registration Complain
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/registration/registered-modules");
                        }}
                      >
                        Registered Modules
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/registration/print-module");
                        }}
                      >
                        Print Module
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>

                  <Nav>
                    <NavDropdown
                      title={
                        <span className="text-black fw-bold fs-6">
                          Evaluation
                        </span>
                      }
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/registration/module-evaluation");
                        }}
                      >
                        Module Evaluation
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>

                  <Nav>
                    <NavDropdown
                      title={
                        <span className="text-black fw-bold fs-6">Add & Drop</span>
                      }
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/registration/add-drop");
                        }}
                      >
                        Add & Drop
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </div>
                {/*end::Menu wrapper*/}
              </div>
              {/*end::Tab panel*/}
              {/*begin::Tab panel*/}
              <div className="tab-pane fade" id="kt_header_navs_tab_2">
                {/*begin::Wrapper*/}
                <div className="header-menu d-flex flex-column align-items-start flex-lg-row">
                  {/*begin::Menu*/}
                  <Nav>
                    <NavDropdown
                      title={
                        <span className="fw-bold fs-6">Student Result</span>
                      }
                    >
                     <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/semester-result");
                        }}
                      >
                        Semester Result
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/interim-transcript");
                        }}
                      >
                        Interim Transcript
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/result-complain");
                        }}
                      >
                        Result Complain
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>

                  <Nav>
                    <NavDropdown
                      title={
                        <span className="text-black fw-bold fs-6">
                          Timetable
                        </span>
                      }
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/exam-timetable");
                        }}
                      >
                        Exam Timetable
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/my-timetable");
                        }}
                      >
                        My Timetable
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <NavDropdown
                      title={
                        <span className="text-black fw-bold fs-6">
                          Digital Transformation
                        </span>
                      }
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/digital-transformation");
                        }}
                      >
                        Digital Transformation
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>

                  <Nav>
                    <NavDropdown
                      title={
                        <span className="text-black fw-bold fs-6">
                          My Progression
                        </span>
                      }
                    >
                     <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/progression-complain");
                        }}
                      >
                        Progression Complain
                      </NavDropdown.Item>
                    </NavDropdown>                    
                  </Nav>
                </div>
              </div>
              {/*end::Tab panel*/}
              {/*begin::Tab panel*/}
              <div className="tab-pane fade" id="kt_header_navs_tab_3">
                <div className="header-menu d-flex flex-column align-items-start flex-lg-row">
                  {/*begin::Menu*/}
                  <Nav>
                    <NavDropdown
                      title={
                        <span className="fw-bold fs-6">Attendance</span>
                      }
                    >
                     <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/attendance");
                        }}
                      >
                        Attendance
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/mark-my-attendance");
                        }}
                      >
                        Mark My Attendance
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <NavDropdown
                      title={
                        <span className="fw-bold fs-6">Continious Assessment</span>
                      }
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/assessment");
                        }}
                      >
                        Continuos Assessment
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </div>
              </div>
              {/*end::Tab panel*/}
              {/*begin::Tab panel*/}
              <div className="tab-pane fade" id="kt_header_navs_tab_5">
                <div className="header-menu d-flex flex-column align-items-start flex-lg-row">
                  {/*begin::Menu*/}
                  
                  <Nav>
                  
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("student/hostel");
                        }}
                      >
                        Hostel Portal
                      </NavDropdown.Item>
                      </Nav>
                      <Nav>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("student/service-desk");
                        }}
                      >
                        Service Desk Portal
                      </NavDropdown.Item>
                  </Nav>
                  <Nav>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("student/internship");
                        }}
                      >
                        Apply Internship
                      </NavDropdown.Item>
                  </Nav>
                  <Nav>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("student/transcript");
                        }}
                      >
                        Apply Transcript
                      </NavDropdown.Item>
                  </Nav>
                  <Nav>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("student/my-thesis");
                        }}
                      >
                        My Final Year
                      </NavDropdown.Item>
                  </Nav>
                </div>
              </div>
              {/*end::Tab panel*/}
              {/*begin::Tab panel*/}
              <div className="tab-pane fade" id="kt_header_navs_tab_4">
                {/*begin::Wrapper*/}
                <div className="header-menu d-flex flex-column align-items-start flex-lg-row">
                  {/*begin::Menu*/}
                  <Nav>
                    <NavDropdown
                      title={
                        <span className="fw-bold fs-6">Student Account</span>
                      }
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/change-of-course");
                        }}
                      >
                        Change Course
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/defer-semester");
                        }}
                      >
                        Defer Semester
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Nav>
                    <NavDropdown
                      title={
                        <span className="fw-bold fs-6">Payment</span>
                      }
                    >
                      <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/payment-roll-over");
                        }}
                      >
                        Payment Roll Over
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                        className="py-3"
                        onClick={() => {
                          navigation("/student/upload-payment");
                        }}
                      >
                        Upload Payment
                      </NavDropdown.Item> */}
                    </NavDropdown>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
      loginData: state.LoginDetails,
  };
};
export default connect(mapStateToProps, null)(Navigation);
