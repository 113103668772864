import React, { useState, useEffect } from "react";
import PageHeader from "../common/pageheader/pageheader";
import Modal from "../common/modal/modal";
import Table from "../common/table/table";
import ThesisForm from "./my-thesis-form";
import axios from "axios";
import { serverLink } from "../../resources/url";
import Loader from "../common/loader/loader";
import { showAlert } from "../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { projectshortCode } from "../../resources/constants";
function Thesis(props) {
    const token = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [staffList, setStaffList] = useState({
        FirstName: "",
        MiddleName: "",
        Surname: "",
        StaffID: ""
    });
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "Supervisor 1",
                field: "Supervisor1",
            },
            {
                label: "Supervisor 2",
                field: "Supervisor2",
            },
            {
                label: "Supervisor 3",
                field: "Supervisor3",
            },
            {
                label: "External Examiner",
                field: "ExternalExaminer",
            },
            {
                label: "Thesis File",
                field: "ThesisFile",
            },
            {
                label: "Plagiarism File",
                field: "PlagiarismFile",
            },
        ],
        rows: [],
    });
    const studentID = props.loginData[0].StudentID

    const [createThesis, setCreateThesis] = useState({
        studentID: props.loginData[0].StudentID,
        Title: "",
        Supervisor1: "",
        Super1: "",
        Supervisor2: "",
        Super2: "",
        Supervisor3: "",
        Super3: "",
        ExternalExaminer: "",
        UploadThesis: "",
        UploadPlagiarism: "",
    });

    const handleUploadThesis = (url) => {
        setCreateThesis({
            ...createThesis,
            UploadThesis: url,
        });
    }

    const handleUploadPlagiarism = (url) => {
        setCreateThesis({
            ...createThesis,
            UploadPlagiarism: url,
        });
    }

    const getAllStaff = async () => {
        await axios.get(`${serverLink}students/thesis/list/all`, token)
            .then((result) => {
                let rows = [];
                result.data.length > 0 && result.data.map((row) => {
                    rows.push({ label: row.FirstName + " " + row.MiddleName + " " + row.Surname, value: row.StaffID })
                })
                setStaffList(rows)
                // if (result.data.length > 0) {
                //     setStaffList(result.data)
                // }
                setIsLoading(false)
            }).catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const getStudentThesis = async () => {
        await axios
            .post(`${serverLink}students/thesis/fetch/thesis`, { studentID: studentID }, token)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((thesis, index) => {
                        rows.push({
                            sn: index + 1,
                            studentID: props.loginData[0].studentID,
                            Title: thesis.Title ?? "N/A",
                            Supervisor1: thesis.Supervisor1 ?? "N/A",
                            Supervisor2: thesis.Supervisor2 ?? "N/A",
                            Supervisor3: thesis.Supervisor3 ?? "N/A",
                            ExternalExaminer: thesis.ExternalExaminer ?? 'N/A',
                            ThesisFile: <a href={`${serverLink}public/uploads/${projectshortCode}/student_uploads/thesis/${thesis.ThesisFile}`} rel="noreferrer" target={"_blank"} className="btn btn-primary btn-sm">View</a> ?? "N/A",
                            PlagiarismFile: <a href={`${serverLink}public/uploads/${projectshortCode}/student_uploads/thesis/${thesis.PlagiarismFile}`} rel="noreferrer" target={"_blank"} className="btn btn-success btn-sm">View</a> ?? "N/A",
                            action: (
                                <button
                                    className="btn btn-sm btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() =>
                                        setCreateThesis({
                                            StudentID: thesis.StudentID,
                                            Title: thesis.Title,
                                            Supervisor1: thesis.Supervisor1,
                                            Supervisor2: thesis.Supervisor2,
                                            Supervisor3: thesis.Supervisor3,
                                            ExternalExaminer: thesis.ExternalExaminer,
                                            uploadImages: thesis.ThesisFile,
                                        })
                                    }
                                >
                                    <i className="fa fa-pen" />
                                </button>
                            ),
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = (e) => {
        setCreateThesis({
            ...createThesis,
            [e.target.id]: e.target.value,
        });
    };

    const onStaffChange1 = (e) => {
        setCreateThesis({
            ...createThesis,
            Supervisor1: e.value,
            Super1: e,
        })
    }

    const onStaffChange2 = (e) => {
        setCreateThesis({
            ...createThesis,
            Supervisor2: e.value,
            Super2: e,
        })
    }

    const onStaffChange3 = (e) => {
        setCreateThesis({
            ...createThesis,
            Supervisor3: e.value,
            Super3: e,
        })
    }

    const onChange = e => {
        if (e.target.id === "UploadPlagiarism") {
            setCreateThesis({
                ...createThesis,
                [e.target.id]: e.target.files[0]
            })
            return;
        }
        else if (e.target.id === 'UploadThesis') {
            setCreateThesis({
                ...createThesis,
                [e.target.id]: e.target.files[0]
            })
            return;
        }
    };

    useEffect(() => {
        getAllStaff();
        getStudentThesis()
    }, []);

    const onSubmit = async () => {
        if (createThesis.Title === "") {
            showAlert("EMPTY FIELD", "Please enter Thesis title", "error");
            return false;
        }
        if (createThesis.Supervisor1 === "") {
            showAlert("EMPTY FIELD", "Please select supervisor 1", "error");
            return false;
        }
        if (createThesis.Supervisor2 === "") {
            showAlert("EMPTY FIELD", "Please select supervisor 2", "error");
            return false;
        }
        if (createThesis.Supervisor3 === "") {
            showAlert("EMPTY FIELD", "Please select supervisor 3", "error");
            return false;
        }
        if (createThesis.ExternalExaminer === "") {
            showAlert("EMPTY FIELD", "Please enter eternal supervisor name", "error");
            return false;
        }
        if (createThesis.UploadThesis === "") {
            showAlert("EMPTY FIELD", "Please Upload Thesis", "error");
            return false;
        }
        if (createThesis.UploadPlagiarism === "") {
            showAlert("EMPTY FIELD", "Please Upload Plagiarism", "error");
            return false;
        }


        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/thesis/thesis/add`, createThesis, token)
            .then((result) => {
                if (result.data.message === "exist") {
                    showAlert("ALREADY SUBMITTED", "You are only allow to submit once!", "error");
                    setIsFormLoading('off')
                    document.getElementById("closeModal").click()
                    setCreateThesis({
                        ...createThesis,
                        studentID: "",
                        Title: "",
                        Supervisor1: "",
                        Super1: "",
                        Supervisor2: "",
                        Super2: "",
                        Supervisor3: "",
                        Super3: "",
                        ExternalExaminer: "",
                        UploadThesis: "",
                        UploadPlagiarism: "",
                    });
                } else if (result.data.message === "success") {
                    if (createThesis.UploadPlagiarism !== "") {
                        const fdt = new FormData();
                        fdt.append('UploadPlagiarism', createThesis.UploadPlagiarism)
                        fdt.append('entry_id', result.data.entry_id)
                        fdt.append('studentID', props.loginData[0].StudentID)
                        axios.post(`${serverLink}students/thesis/Upload/Plagiarism`, fdt)
                    }

                    if (createThesis.UploadThesis !== "") {
                        const fdt2 = new FormData();
                        fdt2.append('UploadThesis', createThesis.UploadThesis)
                        fdt2.append('entry_id', result.data.entry_id)
                        fdt2.append('studentID', props.loginData[0].StudentID)
                        axios.post(`${serverLink}students/thesis/Upload/Thesis`, fdt2)
                    }

                    toast.success("Thesis Submitted Successfully");
                    setIsFormLoading('off')
                    getStudentThesis()
                    document.getElementById("closeModal").click()
                    setCreateThesis({
                        ...createThesis,
                        studentID: "",
                        Title: "",
                        Supervisor1: "",
                        Super1: "",
                        Supervisor2: "",
                        Super2: "",
                        Supervisor3: "",
                        Super3: "",
                        ExternalExaminer: "",
                        UploadThesis: "",
                        UploadPlagiarism: "",
                    });
                }
            })
            .catch((error) => {
                setIsFormLoading('off')
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    };

    return isLoading ? <Loader /> : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Student Thesis"}
                items={["My Final Year", "Student Thesis"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" />
                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-customer-table-toolbar="base"
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"

                                >
                                    Upload Thesis
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <Table data={datatable} />
                    </div>
                </div>
                <Modal title={"STUDENT THESIS"}>
                    <ThesisForm staffList={staffList}
                        isFormLoading={isFormLoading}
                        onEdit={onEdit}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        handleUploadThesis={handleUploadThesis}
                        handleUploadPlagiarism={handleUploadPlagiarism}
                        onStaffChange1={onStaffChange1}
                        onStaffChange2={onStaffChange2}
                        onStaffChange3={onStaffChange3}
                        data={createThesis}
                    />

                </Modal>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Thesis);
