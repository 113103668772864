import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
  DepartmentListReducer,
  FacultyListReducer,
  LoginDetailsReducer,
  PermissionDetailsReducer,
  currentSemesterReducer,
  GeneralReducer,
  HostelDetailsReducer, PersonalInfoDetailsReducer
} from "./detailsReducer";
import {projectshortCode} from "../resources/constants";

const rootReducer = combineReducers({
  LoginDetails: LoginDetailsReducer,
  PermissionDetails: PermissionDetailsReducer,
  FacultyList: FacultyListReducer,
  DepartmentList: DepartmentListReducer,
  currentSemester: currentSemesterReducer,
  general: GeneralReducer,
  HostelDetails: HostelDetailsReducer,
  PersonalInfo: PersonalInfoDetailsReducer
});

const persistConfig = {
  key: projectshortCode.toLowerCase()+'_student_portal',
  storage,
};

export default persistReducer(persistConfig, rootReducer);
