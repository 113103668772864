import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import Loader from '../common/loader/loader';
import { connect } from 'react-redux';
import { projectEmail, projectLogo, projectName, projectNameOnTranscript, projectPhone, projectURL, toTitleCase } from '../../resources/constants';
import "../interim-transcript/interim-transcript.css"
import { Link } from 'react-router-dom';

const InterimTranscript = (props) =>
{
    const token = props.loginData[0].token;
    const [loading, setIsLoading] = useState(false)
    const [ResultLevel, setResultLevel] = useState([])
    const [Results, setResults] = useState([])
    const [finalGradePoint, setFinalGradePoint] = useState([])
    const [finalCreditUnit, setFinalCreditUnit] = useState([])
    const [finalGradePointAverage, setFinalGradePointAverage] = useState([])
    const [checkResult, setCheckResults] = useState([])
    const [outstanding, setOutstanding] = useState([])
    const [notAllRated, setNotAllRated] = useState([]);

    const studentID = props.loginData[0].StudentID;
    const studentSemester = props.loginData[0].StudentSemester;
    const studentLevel = props.loginData[0].StudentLevel

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const today = new Date();

    const dateNow = `${days[today.getDay()]}, ${monthNames[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

    const userData = props.loginData[0];
    const printDate = dateNow;


    const getData = async () =>
    {
        axios.post(`${serverLink}students/change-of-course/get/student/level`, { studentID: studentID }, token).then((result) =>
        {
            if (result.data.length > 0)
            {
                setResultLevel(result.data)
            }
        })
        axios.post(`${serverLink}students/dashboard/check/student/has/result`, { studentID: studentID }, token).then((result) =>
        {
            if (result.data.length > 0)
            {
                setCheckResults(result.data)
            }
        })
        axios.post(`${serverLink}students/change-of-course/get/student/result`, { studentID: studentID, studentLevel: studentLevel, studentSemester: studentSemester }, token).then((result) =>
        {
            setResults([])
            let result_ = [];
            if (result.data.length > 0)
            {
                const notRated = result.data.filter(x => x.IsLecturerRated === 0)
                setNotAllRated(notRated)
                setFinalGradePoint(result.data.map(e => parseInt(e.GradePoints)).reduce((a, b) => a + b, 0));
                setFinalCreditUnit(result.data.map(e => parseInt(e.CreditLoad)).reduce((a, b) => a + b, 0))
                const cgpa_ = result.data.map(e => parseInt(e.CreditLoad)).reduce((a, b) => a + b, 0) !== 0 ? result.data.map(e => parseInt(e.GradePoints)).reduce((a, b) => a + b, 0) / result.data.map(e => parseInt(e.CreditLoad)).reduce((a, b) => a + b, 0) : 0
                const y = parseFloat(cgpa_.toFixed(2));
                setFinalGradePointAverage(y)

                const groupBysemester = result.data.reduce((group, product) =>
                {
                    const { groupByValue } = product;
                    group[groupByValue] = group[groupByValue] ?? [];
                    group[groupByValue].push(product);
                    return group;
                }, {});
                result_ = Object.values(groupBysemester)
                setResults(result_);
            }
        })
        axios.post(`${serverLink}students/dashboard/fetch/outstanding`, { studentID: studentID }, token).then((result) =>
        {
            if (result.data.length > 0)
            {
                setOutstanding(result.data[0]["OutStandingAmount"])
            }
        })
        // axios.post(`${serverLink}students/dashboard/fetch/gpa`, { studentID: studentID }, token).then((result) => {
        //     if (result.data.length > 0) {
        //         setFinalGradePointAverage(result.data[0].cgpa[0].CGPA)
        //       setFinalGradePoint(result.data[0].gd[0].WGP)
        //       setFinalCreditUnit(result.data[0].gd[0].CUR)
        //     }
        //   })


        setIsLoading(false)
    }
    const printNow = () =>
    {
        window.print();
    }

    useEffect(() =>
    {
        getData();
    }, [])

    return loading ? (
        <Loader />
    ) : (

        <>

            {
                checkResult.length > 0 ?
                    <div className='col-md-12'>
                        <div className='row col-md-12'>
                            <alert className="alert alert-info">Please note that all modules must be evaluated before full result is available. To start your evaluation please click here. <Link to='/registration/module-evaluation'>here</Link></alert>
                        </div>
                        <div className="col-md-10 col-md-offset-1 myDivToPrint page">
                            <div className="">
                                <div className="mt-5">
                                    <div className="header">
                                        <img src={projectLogo} alt={projectName} width={100} height={100} />
                                        <span>
                                            <h4 className="mt-2 text-center">{projectNameOnTranscript}</h4>
                                            <h5 className="">INTERIM TRANSCRIPT</h5><br />
                                            <span>Generated on: {printDate}</span>
                                        </span>

                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="col-2"><b>Student ID:</b></div>
                                    <div className="col-4">{userData.StudentID}</div>
                                    <div className="col-2"><b>Faculty:</b></div>
                                    <div className="col-4">{userData.StudentFaculty}</div>
                                </div>
                                <div className="row">
                                    <div className="col-2"><b>Names:</b></div>
                                    <div className="col-4">{userData.StudentName}</div>
                                    <div className="col-2"><b>Department:</b></div>
                                    <div className="col-4">{toTitleCase(userData.StudentCourse)}</div>
                                </div>
                                <div className="row">
                                    <div className="col-2"><b>Gender:</b></div>
                                    <div className="col-4">{userData.StudentGender}</div>
                                    <div className="col-2"><b></b></div>
                                    <div className="col-4"></div>
                                </div>

                                <div className="table-responsive mt-5">
                                    {
                                        Results.length > 0 ?
                                            <>
                                                {Results.map((x, y) =>
                                                {
                                                    let total_cu = x.map(e => parseInt(e.CreditLoad)).reduce((a, b) => a + b, 0)
                                                    let total_grade = x.map(e => parseInt(e.GradePoints)).reduce((a, b) => a + b, 0)
                                                    const gpa_ = total_cu !== 0 ? total_grade / total_cu : 0
                                                    let gpa = parseFloat(gpa_.toFixed(2));
                                                    let isAtleastOneNotRated = x.filter(q => q.IsLecturerRated === 0)

                                                    return (
                                                        <table key={y} className="table" style={{ width: '100%' }}>
                                                            <thead>
                                                                <tr className="main_head">
                                                                    <td colSpan={3} ></td>
                                                                    <td align="left">
                                                                        Level
                                                                    </td>
                                                                    <td>{Results[y][0].StudentLevel}</td>
                                                                    <td align="left">
                                                                        Semester
                                                                    </td>
                                                                    <td>{Results[y][0].StudentSemester}</td>
                                                                </tr>

                                                                <tr style={{ fontWeight: "bold" }} className="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                                                                    <td widtd="15%" align="left"> Course Code</td>
                                                                    <td widtd="45%" align="left">Module Title</td>
                                                                    <td widtd="15%" align="left">Credit Load</td>
                                                                    {/* <td widtd="10%" align="left"> Semester</td> */}
                                                                    <td widtd="10%" align="left"> Score</td>
                                                                    <td widtd="10%" align="left"> Grade</td>
                                                                    <td widtd="10%" align="left"> Grade Point</td>
                                                                    <td widtd="10%" align="left"> Status</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    x.map((z, j) =>
                                                                    {
                                                                        return (
                                                                            <tr key={j} style={{ width: '100%' }}>
                                                                                <td width="15%" align="left">{z.ModuleCode}</td>
                                                                                <td width="45%" align="left">{z.ModuleTitle}</td>
                                                                                <td width="15%">{z.CreditLoad}</td>
                                                                                {/* <td width="10%" align="left"> {z.StudentSemester}</td> */}
                                                                                <td width="10%" align="left"> {z.IsLecturerRated === 0 ? "--" : z.Total}</td>
                                                                                <td width="10%" align="left"> {z.IsLecturerRated === 0 ? "--" : z.StudentGrade}</td>
                                                                                <td width="10%" align="left"> {z.IsLecturerRated === 0 ? "--" : z.GradeObtained}</td>
                                                                                <td width="10%" align="left"> {z.IsLecturerRated === 0 ? "--" : z.Decision}</td>

                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                            <tfoot>
                                                                <tr className="main_foot">
                                                                    <th align="left"><strong> GPA</strong></th>
                                                                    <th align="left"><strong>{isAtleastOneNotRated?.length > 0 ? "--" : gpa}</strong></th>
                                                                    <th width="10%" align="left"><strong>TCR</strong></th>
                                                                    <th width="10%" align="left"><strong>{isAtleastOneNotRated?.length > 0 ? "--" : total_cu}</strong></th>
                                                                    <th width="10%" align="left"><strong>WCE</strong></th>
                                                                    <th width="10%" align="left"><strong>{isAtleastOneNotRated?.length > 0 ? "--" : total_grade}</strong></th>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    )
                                                })}
                                            </>
                                            : <>
                                            </>
                                    }
                                </div>

                                <table width={"100%"}>
                                    <thead>
                                        <tr>
                                            <th align="left">TCR</th>
                                            <th align="left">{notAllRated?.length > 0 ? "--" : finalCreditUnit}</th>
                                            <th align="left">WCE</th>
                                            <th align="left">{notAllRated?.length > 0 ? "--" : finalGradePoint}</th>
                                            <th align="left">CGPA</th>
                                            <th align="left">{notAllRated?.length > 0 ? "--" : finalGradePointAverage}</th>
                                        </tr>
                                        <tr>
                                            <th colSpan="3">Grade Key</th>
                                            <th colSpan="3">Acronym Key</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Alphabet</th>
                                            <th>Point</th>
                                            <th>Description</th>
                                            <th>Acronym</th>
                                            <th colSpan="2">Description</th>
                                        </tr>
                                        <tr>
                                            <td>A</td>
                                            <td>5</td>
                                            <td>First Class/Distinction</td>
                                            <td>TCR</td>
                                            <td colSpan="2">Total Credit Registered</td>
                                        </tr>
                                        <tr>
                                            <td>B</td>
                                            <td>4</td>
                                            <td>Second Class Upper</td>
                                            <td>TCE</td>
                                            <td colSpan="2">Total Credit Earned</td>
                                        </tr>
                                        <tr>
                                            <td>C</td>
                                            <td>3</td>
                                            <td>Second Class Lower</td>
                                            <td>GPA</td>
                                            <td colSpan="2">Grade Point Average</td>
                                        </tr>
                                        <tr>
                                            <td>D</td>
                                            <td>2</td>
                                            <td>Pass</td>
                                            <td>CGPA</td>
                                            <td colSpan="2">Cumulative Grade Point Average</td>
                                        </tr>
                                        <tr>
                                            <td>E</td>
                                            <td>1</td>
                                            <td>Pass</td>
                                            <td>CGPA</td>
                                            <td colSpan="2">Cumulative Grade Point Average</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>INC</td>
                                            <td colSpan="2">Incomplete</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>RM</td>
                                            <td colSpan="2">Repeat Module</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>WCE</td>
                                            <td colSpan="2">Weighted Credit Earned (Credit x Grade Point)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                            <div className="text-center" style={{ margin: "25px" }}>
                                -------- END OF TRANSCRIPT --------
                            </div>
                            <div className="text-center">
                                Any alteration renders this transcript invalid
                            </div>
                            <div className="text-center">
                                <small>Phone: {projectPhone} | Email: {projectEmail} | Website: {projectURL}</small>
                            </div>
                            <button id="printPageButton" onClick={printNow} className="btn btn-secondary">Print <i className="bi-printer" /></button>


                        </div>
                    </div>

                    : <div className='col-md-12 text-center alert alert-danger'>Transcript no available !!!</div>
            }
        </>


    )

}
const mapStateToProps = (state) =>
{
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(InterimTranscript);
