import React, { useEffect, useState } from "react";
import PageHeader from "../common/pageheader/pageheader";
import Modal from "../common/modal/modal";
import Table from "../common/table/table";
import ProgressionForm from "./progression-complain-form";
import Loader from "../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {EmailTemplates, formatDateAndTime, sendEmail} from "../../resources/constants"
import DOMPurify from "dompurify";

function Progression(props) {
  const token  = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [failModuleList, setFailModuleList] = useState([]);
    const [total, setTotal] = useState(0)

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Current Semester",
        field: "CurrentSemester",
      },
      {
        label: "Current Level",
        field: "CurrentLevel",
      },
      {
        label: "Requested Level",
        field: "RequestedLevel",
      },
      {
        label: "Requested Semester",
        field: "RequestedSemester",
      },
      {
        label: "School Semester",
        field: "SemesterCode",
      },
      {
        label: "Reason",
        field: "Reason",
      },
      {
        label: "Status",
        field: "Status",
      },
      {
        label: "InsertedDate",
        field: "InsertedDate",
      },
    ],
    rows: [],
  });

  const [createComplain, setCreateComplain] = useState({
    studentID: props.loginData[0].StudentID,
    StudentName: props.loginData[0].StudentName,
    CurrentLevel: props.loginData[0].StudentLevel,
    CurrentSemester: props.loginData[0].StudentSemester,
    NewLevel: "",
    NewSemester: "",
    Reason: "",
    });

  const studentID = props.loginData[0].StudentID
  const email = props.loginData[0].EmailAddress
  const StudentName= props.loginData[0].StudentName


  const getData = async () => {
    axios.post(`${serverLink}students/progression/module/fail`, { studentID: studentID }, token).then((result) => {
      if (result.data.length > 0) {
      result.data.length > 0 &&
      setFailModuleList (result.data);
        setTotal(result.data.map(e=>parseInt(e.CreditLoad)).reduce((a,b)=>a+b,0))
    }
      
    })
    setIsLoading(false)
  }

  const getComplain = async () => {
    await axios
        .post(`${serverLink}students/progression/fetch/progression/complain`, { studentID: studentID }, token)
        .then((result) => {
            if (result.data.length > 0) {
                let rows = [];
                result.data.map((complain, index) => {
                    rows.push({
                        sn: index + 1,
                        StudentID: complain.StudentID ?? "N/A",
                        SemesterCode: complain.SemesterCode ?? "N/A",
                        CurrentLevel: complain.CurrentLevel ??'N/A',
                        CurrentSemester: complain.CurrentSemester ?? "N/A",
                        RequestedLevel: complain.RequestedLevel ?? "N/A",
                        RequestedSemester: complain.RequestedSemester ?? "N/A",
                        Reason: <blockquote dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(complain.Reason)}}/>  ?? "N/A",
                        Status: complain.TheStatus === 'Applied' ? <p className="badge badge-primary">Applied</p>:  complain.TheStatus === 'Approved' ? <p className="badge badge-success">Approved</p> : <p className="badge badge-danger">Rejected</p> ?? "N/A",
                        InsertedDate: formatDateAndTime(complain.InsertedDate, "date") ?? "N/A",
                        action: (
                            <button
                                className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_general"
                                onClick={() =>
                                    setCreateComplain({
                                      StudentID: complain.StudentID,
                                      SemesterCode: complain.SemesterCode,
                                      CurrentLevel: complain.CurrentLevel,
                                      CurrentSemester: complain.CurrentSemester,
                                      RequestedLevel: complain.RequestedLevel,
                                      RequestedSemester: complain.RequestedSemester,
                                      Reason: complain.Reason,
                                      Status: complain.TheStatus,
                                      InsertedDate: complain.InsertedDate,
                                    })
                                }
                            >
                                <i className="fa fa-pen" />
                            </button>
                        ),
                    });
                });
  
                setDatatable({
                    ...datatable,
                    columns: datatable.columns,
                    rows: rows,
                });
            }
  
            setIsLoading(false);
        })
        .catch((err) => {
            console.log("NETWORK ERROR");
        });
  };

  const onEdit = (e) => {
    setCreateComplain({
        ...createComplain,
        [e.target.id]: e.target.value,
    });
};

const onReasonChange = (e) => {
  setCreateComplain({
      ...createComplain,
      "Reason": e
  })
}


const onSubmit = async () => {

    if (createComplain.NewLevel.trim() === "") {
        showAlert("EMPTY FIELD", "Please select new level", "error");
        return false;
    }
    if (createComplain.NewSemester.trim() === "") {
        showAlert("EMPTY FIELD", "Please select new semester", "error");
        return false;
    }
    if (createComplain.Reason.trim() === "") {
        showAlert("EMPTY FIELD", "Please enter reason", "error");
        return false;
    }
    if (createComplain.Reason.length < 10) {
      showAlert("REASON IS TOO SHORT", "Your reason must contain a minimum of 10 characters", "error");
      return false;
  }
   
        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/progression/submit/progression/complain`, createComplain, token)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Progression Complain Submitted Successfully");
                    const mail = EmailTemplates('2', createComplain)
                    sendEmail(email, mail.subject, mail.title, StudentName, mail.body, '')
                    setIsFormLoading('off')
                    getComplain();
                    document.getElementById("closeModal").click()
                    setCreateComplain({
                        ...createComplain,
                        StudentID: "",
                        NewLevel: "",
                        NewSemester: "",
                        Reason: "",
                    });
                } else if (result.data.message === "exist") {
                    showAlert("PROGRESSION COMPLAIN FAILED", "Sorry, you have an existing progression complain that has not been resolved!", "error").then(e=>{
                      document.getElementById("closeModal").click()
                      setIsFormLoading('off')
                      setCreateComplain({
                        ...createComplain,
                        StudentID: "",
                        NewLevel: "",
                        NewSemester: "",
                        Reason: "",
                    });
                    });
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    
};


useEffect(() => {
  getData();
  getComplain()
}, [])

  return isLoading ? (
    <Loader />
) :(
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader
        title={"Progression Complain"}
        items={["Progression ", "Progression Complain"]}
      />
      <div className="flex-column-fluid">
        <div className="card">
          <div className="card-header border-0 pt-6">
            <div className="card-title" />
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_general"
                >
                  Progression Complain
                </button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <Table data={datatable} />
          </div>
        </div>
        <Modal title={"PROGRESSION COMPLAIN FORM"}>
        <ProgressionForm
        total={total}
        failModuleList={failModuleList}
        data={createComplain} 
        onSubmit={onSubmit} 
        isFormLoading={isFormLoading} 
        onEdit={onEdit} 
        onReasonChange={onReasonChange}
        />
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
      loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(Progression);
