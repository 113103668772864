import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import ModuleEvaluationForm from "./module-evaluation-form";
import { decryptData } from "../../../resources/constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

function StaffRatings(props) {
    const token  = props.loginData[0].token;
    const navigate = useNavigate();
    const _id = window.location.href.split('/')[5];
    const __id = decryptData(_id);
    const entry_id = __id.split('-')[0];
    const module_code = __id.split('-')[2];
    const semester_code = __id.split('-')[1]
    const [isFormLoading, setIsFormLoading] = useState('off');
    
    const [formType, setFormType] = useState('attendance')

    const [disabledForms, setdisabledForms] = useState({
        attendance: '!-active',
        delivery: 'none-inactive',
        presentation: 'none-inactive',
        evaluation: 'none-inactive',
        interpersonal: 'none-inactive',
        suggestion: 'none-inactive'
    })
    const [createEvaluation, setCreateEvaluation] = useState({
        EntryID: entry_id,
        StudentID: props.loginData[0].StudentID,
        Q1: "", Q2: "", Q3: "", Q4: "", Q5: "", Q6: "", Q7: "", Q8: "", Q9: "", Q10: "", Q11: "", Q12: "", Q13: "", Q14: "", Q15: "", Q16: "", Q17: "", Q18: "", Q19: "", Q20: "", Q21: "", Q22: "", Q23: "", Q24: "", Q25: "", Q26: "", Q27: "", Q28: "", Q29: "", Q30: "", Q31: "", Q32: "", Q33: "", Q34: "",
        ModuleCode: module_code,
        SchoolSemester: semester_code
    })
    const onEdit = (e) => {
        setCreateEvaluation({
            ...createEvaluation,
            [e.target.id]: e.target.value,

        });
    };

    const onSubmit = async () => {
        if (createEvaluation.Q1.trim() === "" || createEvaluation.Q2.trim() === "" || createEvaluation.Q3.trim() === "" || createEvaluation.Q4.trim() === "" || createEvaluation.Q6.trim() === "" || createEvaluation.Q7.trim() === "" || createEvaluation.Q8.trim() === "" || createEvaluation.Q9.trim() === "" || createEvaluation.Q10.trim() === "" || createEvaluation.Q11.trim() === "" || createEvaluation.Q12.trim() === "" || createEvaluation.Q13.trim() === "" || createEvaluation.Q14.trim() === "" || createEvaluation.Q15.trim() === "" || createEvaluation.Q16.trim() === "" || createEvaluation.Q17.trim() === "" || createEvaluation.Q18.trim() === "" || createEvaluation.Q19.trim() === "" || createEvaluation.Q20.trim() === "" || createEvaluation.Q21.trim() === "" || createEvaluation.Q22.trim() === "" || createEvaluation.Q23.trim() === "" || createEvaluation.Q25.trim() === "" || createEvaluation.Q26.trim() === "" || createEvaluation.Q27.trim() === "" || createEvaluation.Q28.trim() === "" || createEvaluation.Q29.trim() === "" || createEvaluation.Q30.trim() === "") {
            toast.error("please fill all the required questions")
            return false;
          }
        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/registration/module-evaluation/submit`, createEvaluation, token)
            .then((result) => {
                if (result.data.message === "success") {
                    setIsFormLoading('off')
                    toast.success("Course have been rated successfully")
                    navigate('/registration/module-evaluation')
                }
            })
            .catch((error) => {
                showAlert(
                    "ERROR",
                    "Refresh and fill all the required fields!",
                    "error"
                );
            });

    };

    return (
        <div className="d-flex flex-column flex-row-fluid">
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" >
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">

                                <li className="nav-item">
                                    {/* style={{pointerEvents:`${disabledForms.attendance.split('-')[0]}`}} */}
                                    <a className={`nav-link text-active-primary pb-4 ${disabledForms.attendance.split('-')[1]}`} data-bs-toggle="tab" href="#attendance" onClick={() => { setFormType('attendance') }}>Attendance</a>
                                </li>

                                <li className="nav-item" >
                                    <a className={`nav-link text-active-primary pb-4 ${disabledForms.delivery.split('-')[1]}`} data-kt-countup-tabs="true" data-bs-toggle="tab" href="#lecturerDelivery" onClick={() => { setFormType('delivery') }}>Lecturer Delivery</a>
                                </li>

                                <li className="nav-item">
                                    <a className={`nav-link text-active-primary pb-4 ${disabledForms.presentation.split('-')[1]}`} data-bs-toggle="tab" href="#lecturerPresentation" onClick={() => { setFormType('presentation') }}>Lecturer Presentation</a>
                                </li>

                                <li className="nav-item">
                                    <a className={`nav-link text-active-primary pb-4 ${disabledForms.evaluation.split('-')[1]}`} data-bs-toggle="tab" href="#lecturerEvaluation" onClick={() => { setFormType('evaluation') }}>Lecturer's Evaluation</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link text-active-primary pb-4 ${disabledForms.interpersonal.split('-')[1]}`} data-bs-toggle="tab" href="#communication" onClick={() => { setFormType('interpersonal') }}>Communication & Interpersonal Skills</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link text-active-primary pb-4 ${disabledForms.suggestion.split('-')[1]}`} data-bs-toggle="tab" href="#suggestion" onClick={() => { setFormType('suggestion') }}>Suggestion</a>
                                </li>

                            </ul>

                        </div>

                    </div>
                    <div className="card-body pt-0">
                        <ModuleEvaluationForm
                            setdisabledForms={setdisabledForms}
                            setFormType={setFormType}
                            disabledForms={disabledForms}
                            data={createEvaluation}
                            formType={formType}
                            onSubmit={onSubmit}
                            isFormLoading={isFormLoading}
                            onEdit={onEdit}
                        /> </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(StaffRatings);
