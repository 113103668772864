import React from "react";
import SimpleFileUpload from 'react-simple-file-upload';
import { projectshortCode, simpleFileUploadAPIKey } from '../../resources/constants'
export default function UploadPaymentForm(props) {
    return (
        <>
            <div className="form-group mb-4">
                <label htmlFor="SchoolSemester">Select Semester Code</label>
                <select
                    name="SchoolSemester"
                    id="SchoolSemester"
                    className="form-select"
                    onChange={props.onEdit}
                    value={props.data.SchoolSemester}
                >   <option value="">Select New Semester</option>
                    {
                        props.semesterList.length > 0 && props.semesterList.map((x, y) => {
                            return <option value={x.SemesterCode} key={y}>{x.SemesterCode}</option>

                        })
                    }

                </select>

            </div>
            <div className="form-group mb-4">
                <label htmlFor="AmountPaid">Amount Paid</label>
                <input
                    placeholder="Enter the Amount Paid (100000)"
                    type="number"
                    name="AmountPaid"
                    id="AmountPaid"
                    className="form-control"
                    onChange={props.onEdit}
                    value={props.data.AmountPaid}
                />
            </div>

            <div className="form-group mb-4 col-md-12">
                <label htmlFor="PaymentFor">Payment For (You can check multiple)</label>
                <div className="row">
                    <div className="col-md-4 mt-2 mb-2">
                        <div className="form-check form-check-custom">
                            <input type="checkbox" className="form-check-input" onChange={props.updateCheckbox} id="Tuition" value={"Tuition"} name="Tuition" /> Tuition
                        </div>
                    </div>
                    <div className="col-md-4 mt-2 mb-2">
                        <div className="form-check form-check-custom">
                            <input type="checkbox" onChange={props.updateCheckbox} className="form-check-input" id="Hostel" value={"Hostel"} name="Hostel" /> Hostel
                        </div>
                    </div>
                    <div className="col-md-4 mt-2 mb-2">
                        <div className="form-check form-check-custom">
                            <input type="checkbox" onChange={props.updateCheckbox} className="form-check-input" id="SIWES" value={"SIWES"} name="SIWES" /> SIWES
                        </div>
                    </div>
                    <div className="col-md-4 mt-2 mb-2">
                        <div className="form-check form-check-custom">
                            <input type="checkbox" onChange={props.updateCheckbox} className="form-check-input" id="Feeding" value={"Feeding"} name="Feeding" /> Feeding
                        </div>
                    </div>
                    <div className="col-md-4 mt-2 mb-2">
                        <div className="form-check form-check-custom">
                            <input type="checkbox" onChange={props.updateCheckbox} className="form-check-input" id="Laundry" value={"Laundry"} name="Laundry" /> Laundry
                        </div>
                    </div>
                </div>

            </div>
            <div className="form-group mb-4">
                <label htmlFor="Document">Upload Receipt</label>
                {/* <SimpleFileUpload
                    apiKey={simpleFileUploadAPIKey}
                    tag={`${projectshortCode}-upload-payment`}
                    onSuccess={props.handleDocument}
                    preview="false"
                    width="100%"
                    height="100"
                /> */}
                <input type="file" id="Document" className="form-control" onChange={props.onFileChange} />

            </div>
            <div className="form-group pt-2">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                </button>
            </div>

        </>
    )
}